<mat-form-field class="nav-filter-field datepicker" subscriptSizing="dynamic">
  <input
    matInput
    readonly
    [matDatepicker]="picker"
    (click)="picker.open()"
    [formControl]="date"
    (dateChange)="dateChange($event)"
  />
  <mat-datepicker-toggle matSuffix [for]="picker">
    <mat-icon class="date-arrow" matDatepickerToggleIcon
      >keyboard_arrow_down</mat-icon
    >
  </mat-datepicker-toggle>
</mat-form-field>
<mat-datepicker #picker></mat-datepicker>

@if (services.length) {
  <mat-form-field class="nav-filter-field services" subscriptSizing="dynamic">
    <mat-select [formControl]="selectFormControl">
      @for (option of services; track option) {
        <mat-option
          (onSelectionChange)="selectService($event)"
          [value]="option"
          >{{ option }}</mat-option
        >
      }
    </mat-select>
  </mat-form-field>
}

@if (services.length && kdsSettings?.show_order_item) {
  <mat-form-field
    class="nav-filter-field search-field"
    subscriptSizing="dynamic"
  >
    <mat-label>{{
      'shared.navigation-filters.search-product' | transloco
    }}</mat-label>
    <input matInput type="text" name="search" [formControl]="searchProduct" />
    @if (searchProduct.value) {
      <button
        mat-icon-button
        matSuffix
        (click)="clearProductFilter($event, 'product')"
      >
        <mat-icon>clear</mat-icon>
      </button>
    } @else {
      <button mat-icon-button matSuffix>
        <mat-icon>search</mat-icon>
      </button>
    }
  </mat-form-field>
}

@if (services.length && kdsSettings?.show_order_table) {
  <mat-form-field
    class="nav-filter-field search-field"
    subscriptSizing="dynamic"
  >
    <mat-label>{{
      'shared.navigation-filters.search-table' | transloco
    }}</mat-label>
    <input matInput type="text" name="search" [formControl]="searchTable" />
    @if (searchTable.value) {
      <button
        mat-icon-button
        matSuffix
        (click)="clearProductFilter($event, 'table')"
      >
        <mat-icon>clear</mat-icon>
      </button>
    } @else {
      <button mat-icon-button matSuffix>
        <mat-icon>search</mat-icon>
      </button>
    }
  </mat-form-field>
}

@if (
  services.length &&
  (kdsSettings?.show_consumer_name || kdsSettings?.show_consumer_room)
) {
  <mat-form-field
    class="nav-filter-field search-field"
    subscriptSizing="dynamic"
  >
    <mat-label>{{
      'shared.navigation-filters.consumer-fields' | transloco
    }}</mat-label>
    <input matInput type="text" name="search" [formControl]="searchConsumer" />
    @if (searchConsumer.value) {
      <button
        mat-icon-button
        matSuffix
        (click)="clearProductFilter($event, 'consumer')"
      >
        <mat-icon>clear</mat-icon>
      </button>
    } @else {
      <button mat-icon-button matSuffix>
        <mat-icon>search</mat-icon>
      </button>
    }
  </mat-form-field>
}
