@if (isLoading$ | async) {
  <mat-spinner></mat-spinner>
} @else if ((ticketsSectionCount$ | async) === 0) {
  <div class="no-orders">
    <p class="mat-h3">
      {{ 'sections.no-orders' | transloco }}
    </p>
    @if (
      (sectionsDisplayed$ | async)?.length &&
      (availableServices$ | async)?.length
    ) {
      <button mat-button class="clear-filters" (click)="showAllSections()">
        {{ 'sections.clear-filters' | transloco }}
      </button>
    }
  </div>
} @else if (serviceStatus$ | async) {
  <chef-section-panels
    class="container"
    [clock]="clock$ | async"
    [kdsSettings]="kdsSettings$ | async"
    [printerConnected]="printerConnected$ | async"
    [serviceStatus]="(serviceStatus$ | async) ?? 1"
    [sections]="
      (sectionsDisplayed$ | async)?.length
        ? (sectionsDisplayed$ | async)!
        : (availableSections$ | async)!
    "
    [tickets]="tickets$ | async"
    (pageClick)="pageClick(2, $event)"
    (updateTicketStatusEvent)="updateTicketStatus(2, $event)"
    (undoTicketStatusEvent)="undoTicketStatus(2, $event)"
    (deleteTicketEvent)="deleteTicket(2, $event)"
    (printSingleTicket)="printTicket($event)"
  ></chef-section-panels>
}
