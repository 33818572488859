import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { SectionTicket } from 'src/app/models/tickets';
import { printTicket } from 'src/app/reducers/printer/printer.actions';
import { selectCurrentService } from 'src/app/reducers/sections/sections.selectors';
import { fetchAllSectionTickets } from 'src/app/reducers/tickets/tickets.actions';
import {
  selectDoneTickets,
  selectDoneTicketsSectionCount,
} from 'src/app/reducers/tickets/tickets.selectors';
import { PageTicketsComponent } from 'src/app/shared/components/page-tickets/page-tickets.component';
import { TranslocoPipe } from '@ngneat/transloco';
import { AsyncPipe } from '@angular/common';
import { SectionPanelsComponent } from '../../shared/components/section-panels/section-panels.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'chef-done',
  templateUrl: './done.component.html',
  styleUrls: [
    '../../shared/components/page-tickets/page-tickets.component.scss',
    './done.component.scss',
  ],
  standalone: true,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    SectionPanelsComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class DoneComponent extends PageTicketsComponent implements OnInit {
  tickets$ = this.ngrxStore.select(selectDoneTickets);
  ticketsSectionCount$ = this.ngrxStore.select(selectDoneTicketsSectionCount);

  ngOnInit(): void {
    this.ngrxStore
      .select(selectCurrentService)
      .pipe(take(1))
      .subscribe((val) => {
        if (val !== undefined)
          this.ngrxStore.dispatch(
            fetchAllSectionTickets({ service_status: 4 }),
          );
      });
  }

  printTicket(ticket: SectionTicket) {
    this.ngrxStore.dispatch(printTicket({ ticket }));
  }
}
