import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CustomData, ServiceStatus } from 'src/app/models/tickets';
import { KdsSettings } from 'src/app/models/user';
import { TranslocoPipe } from '@ngneat/transloco';
import { NgClass, DatePipe, KeyValuePipe } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'chef-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    NgClass,
    DatePipe,
    KeyValuePipe,
    TranslocoPipe,
  ],
})
export class TicketComponent implements OnChanges {
  @Input() clock: Date | null = null;
  @Input() id!: number;
  @Input() ids!: number[];
  @Input() customData!: CustomData;
  @Input() customerName!: string;
  @Input() hasBirthday!: boolean;
  @Input() serviceStatus!: ServiceStatus;
  @Input() kdsSettings!: KdsSettings | null;
  @Input() printerConnected!: boolean | null;
  @Input() datedMenu!: boolean | null;
  @Input() consumerTexture!: number | null;
  @Input() consumerConsistency!: number | null;
  @Input() consumerPortionSize!: number | null;
  @Input() consumerDiets!: string[];
  @Input() consumerAllergies!: string[];
  @Input() consumerIntolerances!: string[];

  @Input() item!: string[];
  @Input() itemOption!: number;
  @Input() description!: string[];
  @Input() quantity!: number[];
  @Input() portionSize!: number[];
  @Input() consistency!: (1 | 2 | 3 | 4 | null)[];
  @Input() texture!: (1 | 2 | 3 | 4 | 5 | null)[];
  @Input() variants!: string[];
  @Input() diets: string[] = [];

  @Input() tableNumber!: string;
  @Input() room!: string | null;
  @Input() roomFloor!: string | null;
  @Input() type!: string | null;
  @Input() isUser!: boolean;

  @Input() vip!: boolean;
  @Input() expedite!: boolean;
  @Input() specialOrder!: boolean;

  @Input() ordered_time!: string | null;
  @Input() fired_time!: string | null;
  @Input() done_time!: string | null;

  @Output() updateTicketStatusEvent = new EventEmitter<number[]>();
  @Output() undoTicketStatusEvent = new EventEmitter<number[]>();
  @Output() deleteTicketEvent = new EventEmitter<number[]>();
  @Output() printTicketEvent = new EventEmitter<void>();

  itemClass = 'fire-button';
  itemClassNext = 'done-button';
  itemIcon = 'check';
  samePortionSize = false;
  sameTexture = false;
  sameConsistency = false;
  timestamp: Date | null = null;
  timeDuration: number | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    if ('portionSize' in changes && this.portionSize) {
      this.samePortionSize = this.allEqual(this.portionSize);
    }
    if ('consistency' in changes && this.consistency) {
      this.sameConsistency = this.allEqual(this.consistency);
    }
    if ('texture' in changes && this.texture) {
      this.sameTexture = this.allEqual(this.texture);
    }
    if ('serviceStatus' in changes && this.serviceStatus) {
      this.timestamp = this.getTimestamp();
    }
    if ('serviceStatus' in changes && this.serviceStatus) {
      this.setTimeDuration();
      this.itemClass = this.getClassName(this.serviceStatus);
      this.itemClassNext =
        this.serviceStatus === 4
          ? ''
          : this.getClassName((this.serviceStatus + 1) as ServiceStatus);
      this.itemIcon = this.getIcon(this.serviceStatus);
    }
    if ('clock' in changes && this.clock && this.serviceStatus) {
      this.setTimeDuration();
    }
  }

  allEqual = (arr: (string | boolean | number | null)[]): boolean =>
    arr.every((v) => v === arr[0]);

  getClassName(serviceStatus: ServiceStatus): string {
    switch (serviceStatus) {
      case 1:
        return 'reservation-button';
      case 2:
        return 'order-button';
      case 3:
        return 'fire-button';
      default:
        return 'done-button';
    }
  }

  getIcon(serviceStatus: ServiceStatus): string {
    switch (serviceStatus) {
      case 1:
        return 'table_restaurant';
      case 2:
        return 'bolt';
      default:
        return 'check';
    }
  }

  getTimestamp = (): Date | null => {
    if (!this.serviceStatus || this.serviceStatus === 1) return null;
    const timestampField = {
      2: this.ordered_time,
      3: this.fired_time,
      4: this.done_time,
    };
    return new Date(
      timestampField[
        this.serviceStatus.toString() as '2' | '3' | '4'
      ] as string,
    );
  };

  getTimeDiffToNow(startDate: string | null = null): number {
    const startTime = new Date(startDate || '');
    const now = new Date();
    const difference = now.getTime() - startTime.getTime();
    return Math.round(difference / 60000);
  }

  getTimeToDone(): number {
    const startTime = new Date(this.fired_time || '');
    const endTime = new Date(this.done_time || '');
    const difference = endTime.getTime() - startTime.getTime();
    return Math.round(difference / 60000);
  }

  setTimeDuration(): void {
    if (this.serviceStatus === 2) {
      this.timeDuration = this.getTimeDiffToNow(this.ordered_time);
    } else if (this.serviceStatus === 3) {
      this.timeDuration = this.getTimeDiffToNow(this.fired_time);
    } else if (this.serviceStatus === 4) {
      this.timeDuration = this.getTimeToDone();
    } else {
      this.timeDuration = null;
    }
  }
}
