import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep, uniqBy } from 'lodash-es';
import { Results } from 'src/app/models/generics';
import {
  SectionTicket,
  ServiceStatus,
  serviceStatusesNames,
  ticketSortingOrder,
} from 'src/app/models/tickets';

import {
  addSectionTicket,
  changeOrderStatus,
  clearAllSectionTickets,
  clearSectionTickets,
  deleteTicketLocally,
  setSectionTickets,
  setSectionTicketsLoading,
  setUndoTicketStatus,
  setUpdatedTicketStatus,
  setUpdatedTickets,
} from './tickets.actions';
import { sorter } from 'src/app/shared/utils.functions';

export interface TicketsState {
  reservation: { [key: string]: Results<SectionTicket> };
  ordered: { [key: string]: Results<SectionTicket> };
  fired: { [key: string]: Results<SectionTicket> };
  done: { [key: string]: Results<SectionTicket> };
}

export const initialState: TicketsState = {
  reservation: {},
  ordered: {},
  fired: {},
  done: {},
};

const statusName = (service_status: ServiceStatus, adj: -1 | 0 | 1 = 0) =>
  serviceStatusesNames[service_status - 1 + adj];

const _ticketsReducer = createReducer(
  initialState,
  on(setSectionTickets, (state, { service_status, payload }) => {
    return {
      ...state,
      [statusName(service_status)]: {
        ...state[statusName(service_status)],
        ...payload,
      },
    };
  }),
  on(addSectionTicket, (state, { payload, page_size }) => {
    // Deep copy the state
    const newState = cloneDeep(state);

    // Ignore adding the ticket if the section is not displayed
    if (
      newState[statusName(payload.service_status)][payload.section] ===
      undefined
    ) {
      return { ...state };
    }

    // Check if the new status has this section key. If not, initialize it.
    if (!newState[statusName(payload.service_status)][payload.section]) {
      newState[statusName(payload.service_status)][payload.section] = {
        count: 0,
        next: '',
        previous: '',
        results: [],
        numberOfPages: 0,
        currentPage: 0,
        isLoading: true,
      };
    }

    // sort the result
    const newTickets = [
      ...newState[statusName(payload.service_status)][payload.section].results,
      payload,
    ].sort((a, b) => sorter(a, b, ticketSortingOrder[payload.service_status]));

    // Add the SectionTickets to the new service status up to the page size limit
    newState[statusName(payload.service_status)][payload.section].results =
      page_size >= newTickets.length
        ? newTickets
        : newTickets.slice(0, page_size);

    // Update the number of pages
    if (page_size < newTickets.length) {
      newState[statusName(payload.service_status)][
        payload.section
      ].numberOfPages =
        (newState[statusName(payload.service_status)][payload.section]
          .numberOfPages ?? 0) + 1;
    }

    // Update the count
    newState[statusName(payload.service_status)][payload.section].count += 1;

    return newState;
  }),
  on(setUpdatedTicketStatus, (state, { service_status, ids, section }) => {
    if (state?.[statusName(service_status)][section] === undefined) {
      return { ...state };
    }
    let selectedTickets: SectionTicket[] = [];
    let previousTickets: SectionTicket[] =
      state?.[statusName(service_status)][section].results;
    const timestamp = new Date().toISOString();
    ids.forEach((id) => {
      state?.[statusName(service_status)][section].results
        .filter((t) => t.ids.includes(id))
        .forEach((ticket) => {
          const updatedTicket = {
            ...ticket,
            service_status: (service_status + 1) as ServiceStatus,
          };
          if (service_status === ServiceStatus.RESERVED) {
            updatedTicket.service_ordered_time = timestamp;
          } else if (service_status === ServiceStatus.ORDERED) {
            updatedTicket.service_fired_time = timestamp;
          } else if (service_status === ServiceStatus.FIRED) {
            updatedTicket.service_done_time = timestamp;
          }
          selectedTickets.push(updatedTicket);
          previousTickets = previousTickets.filter(
            (currentTicket) => currentTicket.id !== ticket.id,
          );
        });
    });
    selectedTickets = uniqBy(selectedTickets, 'id');
    // sort the result
    const newTickets = [
      ...state[statusName(service_status, 1)][section].results,
      ...selectedTickets,
    ].sort((a, b) =>
      sorter(a, b, ticketSortingOrder[(service_status + 1) as 1 | 2 | 3 | 4]),
    );
    return {
      ...state,
      [statusName(service_status, 1)]: {
        ...state[statusName(service_status, 1)],
        [section]: {
          ...state[statusName(service_status, 1)][section],
          count:
            state[statusName(service_status, 1)][section].count +
            selectedTickets.length,
          results: newTickets,
        },
      },
      [statusName(service_status)]: {
        ...state[statusName(service_status)],
        [section]: {
          ...state[statusName(service_status)][section],
          count:
            state[statusName(service_status)][section].count -
            selectedTickets.length,
          results: [...previousTickets],
        },
      },
    };
  }),
  on(
    setUndoTicketStatus,
    (state, { service_status, ids, section, new_service_status }) => {
      if (state?.[statusName(service_status)][section] === undefined) {
        return { ...state };
      }
      let selectedTickets: SectionTicket[] = [];
      let previousTickets: SectionTicket[] =
        state?.[statusName(service_status)][section].results;
      const timestamp = new Date().toISOString();
      ids.forEach((id) => {
        state?.[statusName(service_status)][section].results
          .filter((t) => t.ids.includes(id))
          .forEach((ticket) => {
            const updatedTicket = {
              ...ticket,
              service_status:
                new_service_status ?? ((service_status - 1) as ServiceStatus),
            };
            if (service_status === ServiceStatus.FIRED) {
              updatedTicket.service_ordered_time = timestamp;
            } else if (service_status === ServiceStatus.DONE) {
              updatedTicket.service_fired_time = timestamp;
            }
            selectedTickets.push(updatedTicket);
            previousTickets = previousTickets.filter(
              (currentTicket) => currentTicket.id !== ticket.id,
            );
          });
      });
      selectedTickets = uniqBy(selectedTickets, 'id');

      // sort the result
      const newTickets = [
        ...state[statusName(service_status, -1)][section].results,
        ...selectedTickets,
      ].sort((a, b) =>
        sorter(a, b, ticketSortingOrder[(service_status - 1) as ServiceStatus]),
      );

      return {
        ...state,
        [statusName(service_status, -1)]: {
          ...state[statusName(service_status, -1)],
          [section]: {
            ...state[statusName(service_status, -1)][section],
            count:
              state[statusName(service_status, -1)][section].count +
              selectedTickets.length,
            results: newTickets,
          },
        },
        [statusName(service_status)]: {
          ...state[statusName(service_status)],
          [section]: {
            ...state[statusName(service_status)][section],
            count:
              state[statusName(service_status)][section].count -
              selectedTickets.length,
            results: [...previousTickets],
          },
        },
      };
    },
  ),
  on(
    deleteTicketLocally,
    (state, { service_status, ids, section, pageSize, count }) => {
      if (state?.[statusName(service_status)][section] === undefined) {
        return { ...state };
      }
      let previousTickets: SectionTicket[] =
        state?.[statusName(service_status)][section].results;
      ids.forEach((id) => {
        state?.[statusName(service_status)][section].results
          .filter((t) => t.ids.includes(id))
          .forEach((ticket) => {
            previousTickets = previousTickets.filter(
              (currentTicket: SectionTicket) => currentTicket.id !== ticket.id,
            );
          });
      });
      return {
        ...state,
        [statusName(service_status)]: {
          ...state[statusName(service_status)],
          [section]: {
            ...state[statusName(service_status)][section],
            results: [...previousTickets],
            count: state[statusName(service_status)][section].count - count,
            numberOfPages: Math.ceil(
              (state[statusName(service_status)][section].count - count) /
                pageSize,
            ),
          },
        },
      };
    },
  ),
  on(
    setSectionTicketsLoading,
    (state, { service_status, section, loading }) => {
      const sectionEmpty = !(section in state[statusName(service_status)]);
      const sectionInitialState = {
        count: 0,
        next: null,
        previous: null,
        results: [],
        numberOfPages: 0,
        currentPage: 0,
        isLoading: true,
      };
      return {
        ...state,
        [statusName(service_status)]: {
          ...state[statusName(service_status)],
          [section]: {
            ...state[statusName(service_status)][section],
            ...(sectionEmpty ? sectionInitialState : { isLoading: loading }),
          },
        },
      };
    },
  ),
  on(clearAllSectionTickets, () => {
    return {
      ...initialState,
    };
  }),
  on(clearSectionTickets, (state, { section }) => {
    const reservationState = { ...state.reservation };
    delete reservationState[section];
    const orderedState = { ...state.ordered };
    delete orderedState[section];
    const firedState = { ...state.fired };
    delete firedState[section];
    const doneState = { ...state.done };
    delete doneState[section];
    return {
      ...state,
      reservation: reservationState,
      ordered: orderedState,
      fired: firedState,
      done: doneState,
    };
  }),
  on(
    changeOrderStatus,
    (state, { ids, previous_status, new_status, items, page_size }) => {
      // Deep copy the state
      const newState = cloneDeep(state);

      // Loop through all sections within the previous service status
      Object.keys(newState[statusName(previous_status)]).forEach(
        (sectionKey) => {
          const filteredItems = items.filter(
            (item) => item.section === sectionKey,
          );

          // Remove the SectionTickets from the previous service status
          newState[statusName(previous_status)][sectionKey].results = newState[
            statusName(previous_status)
          ][sectionKey].results.filter(
            (ticket) => !ids.some((id) => ticket.ids.includes(id)),
          );

          // Update the count in the previous service status
          newState[statusName(previous_status)][sectionKey].count -=
            filteredItems.length;

          // Check if the new status has this section key. If not, initialize it.
          if (!newState[statusName(new_status)][sectionKey]) {
            newState[statusName(new_status)][sectionKey] = {
              count: 0,
              next: '',
              previous: '',
              results: [],
              numberOfPages: 0,
              currentPage: 0,
              isLoading: true,
            };
          }

          // sort the result
          const newTickets = [
            ...newState[statusName(new_status)][sectionKey].results,
            ...filteredItems,
          ].sort((a, b) => sorter(a, b, ticketSortingOrder[new_status]));

          // Add the SectionTickets to the new service status up to the page size limit
          newState[statusName(new_status)][sectionKey].results =
            page_size >= newTickets.length
              ? newTickets
              : newTickets.slice(0, page_size);

          // Update the number of pages
          if (page_size < newTickets.length) {
            newState[statusName(new_status)][sectionKey].numberOfPages =
              (newState[statusName(new_status)][sectionKey].numberOfPages ??
                0) + Math.ceil(filteredItems.length / page_size);
          }

          // Update the count in the new service status
          newState[statusName(new_status)][sectionKey].count +=
            filteredItems.length;
        },
      );

      return newState;
    },
  ),
  on(setUpdatedTickets, (state, { service_status, section, items }) => {
    const updatedTickets = state[statusName(service_status)][
      section
    ]?.results.map((ticket: SectionTicket) => {
      const updatedItem = items.find(
        (item: SectionTicket) => item.id === ticket.id,
      );
      if (updatedItem) return updatedItem;
      return ticket;
    });
    if (!updatedTickets) return { ...state };
    return {
      ...state,
      [statusName(service_status)]: {
        ...state[statusName(service_status)],
        [section]: {
          ...state[statusName(service_status)][section],
          results: updatedTickets,
        },
      },
    };
  }),
);

export function ticketsReducer(
  state: TicketsState | undefined,
  action: Action,
) {
  return _ticketsReducer(state, action);
}
