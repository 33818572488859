import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Results } from 'src/app/models/generics';
import { SectionTicket, ServiceStatus } from 'src/app/models/tickets';
import { KdsSettings } from 'src/app/models/user';
import { TranslocoPipe } from '@ngneat/transloco';
import { SectionPaginationComponent } from '../section-pagination/section-pagination.component';
import { TicketComponent } from '../ticket/ticket.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'chef-section-panels',
  templateUrl: './section-panels.component.html',
  styleUrls: ['./section-panels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    TicketComponent,
    SectionPaginationComponent,
    TranslocoPipe,
  ],
})
export class SectionPanelsComponent {
  @Input() clock: Date | null = null;
  @Input() kdsSettings!: KdsSettings | null;
  @Input() printerConnected!: boolean | null;
  @Input() printTickets = false;
  @Input() sections!: string[];
  @Input() serviceStatus!: ServiceStatus;
  @Input() tickets!: { [key: string]: Results<SectionTicket> } | null;
  @Output() updateTicketStatusEvent = new EventEmitter<{
    ids: number[];
    section: string;
    ticket?: SectionTicket;
  }>();
  @Output() undoTicketStatusEvent = new EventEmitter<{
    ids: number[];
    section: string;
    ticket?: SectionTicket;
  }>();
  @Output() deleteTicketEvent = new EventEmitter<{
    ids: number[];
    section: string;
  }>();
  @Output() pageClick = new EventEmitter<{
    pageNumber: number;
    url: string;
    key: string;
    previousUrl: string;
    pageSize: number;
    showLoading: boolean;
  }>();

  @Output() printSingleTicket = new EventEmitter<SectionTicket>();
  @Output() printMultipleTickets = new EventEmitter<SectionTicket[]>();

  changePage(pageNumber: number, section: string, showLoading = true): void {
    console.log(showLoading);
    this.pageClick.emit({
      key: section,
      pageNumber,
      previousUrl: this.tickets?.[section]?.previous ?? '',
      url: this.tickets?.[section]?.next ?? '',
      pageSize: this.kdsSettings?.display_page_size ?? 10,
      showLoading,
    });
  }

  completeAll(
    sectionTickets: SectionTicket[] | undefined,
    section: string,
    numberPages: number | undefined,
  ) {
    const ids: number[] =
      sectionTickets?.map((ticket) => ticket.ids).flat(1) ?? [];
    this.updateTicketStatusEvent.emit({
      ids,
      section,
    });
    if (numberPages && numberPages > 1) {
      setTimeout(() => {
        this.changePage(0, section);
      }, 1000);
    }
    if (sectionTickets?.length && this.printTickets) {
      this.printMultipleTickets.emit(sectionTickets);
    }
  }

  changeTicketStatus(
    undo: boolean,
    ids: number[],
    section: string,
    remainingTickets: number | undefined,
    numberPages: number | undefined,
    ticket?: SectionTicket,
  ): void {
    const emitter = undo
      ? this.undoTicketStatusEvent
      : this.updateTicketStatusEvent;
    emitter.emit({
      ids,
      section,
      ticket,
    });
    if (
      numberPages &&
      numberPages > 1 &&
      (remainingTickets === 1 || this.kdsSettings?.display_refresh_individually)
    ) {
      setTimeout(() => {
        this.changePage(0, section, remainingTickets === 1);
      }, 500);
    }
  }

  deleteTicket(
    ids: number[],
    section: string,
    remainingTickets: number | undefined,
    numberPages: number | undefined,
  ): void {
    this.deleteTicketEvent.emit({
      ids,
      section,
    });
    if (numberPages && numberPages > 1 && remainingTickets === 1) {
      setTimeout(() => {
        this.changePage(0, section);
      });
    }
  }
}
