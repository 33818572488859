import { Component, OnInit } from '@angular/core';
import {
  NonNullableFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslocoService, TranslocoPipe } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { emailRegexPattern } from 'src/app/models/authentication';
import { State } from 'src/app/reducers';
import { loginUser } from 'src/app/reducers/user/user.actions';
import { selectLoginSpinnerState } from 'src/app/reducers/user/user.selectors';
import { parseSupportedLanguage } from 'src/app/shared/utils.functions';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { FieldWithErrorsComponent } from '../../shared/components/field-with-errors/field-with-errors.component';
import { FormWithErrorsComponent } from '../../shared/components/form-with-errors/form-with-errors.component';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'chef-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    FormWithErrorsComponent,
    FormsModule,
    ReactiveFormsModule,
    FieldWithErrorsComponent,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class LoginComponent implements OnInit {
  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(emailRegexPattern)]],
    password: [
      '',
      [Validators.required, Validators.minLength(8), Validators.maxLength(32)],
    ],
  });
  spinnerState$ = this.ngrxStore.select(selectLoginSpinnerState);

  constructor(
    private fb: NonNullableFormBuilder,
    private ngrxStore: Store<State>,
    private translate: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.translate.setActiveLang(parseSupportedLanguage(navigator.language));
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      this.ngrxStore.dispatch(loginUser(this.loginForm.getRawValue()));
    }
  }
}
