import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectIsLogged,
  selectUser,
} from 'src/app/reducers/user/user.selectors';

import { State } from './reducers';
import {
  selectSections,
  selectSectionsDisplayed,
  selectServiceList,
} from './reducers/sections/sections.selectors';
import {
  selectGlobalError,
  selectIsPrinterConnected,
  selectIsPrinterPaired,
  selectPrinter,
} from './reducers/shared/shared.selectors';
import {
  selectTotalDoneTickets,
  selectTotalFiredTickets,
  selectTotalOrderTickets,
  selectTotalReservationsTickets,
} from './reducers/tickets/tickets.selectors';
import {
  selectIsKdsUser,
  selectKdsSettings,
} from './reducers/user/user.selectors';
import { FooterComponent } from './global/footer/footer.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { ErrorBannerComponent } from './global/error-banner/error-banner.component';
import { HeaderComponent } from './global/header/header.component';
import { NavigationFiltersComponent } from './global/navigation-filters/navigation-filters.component';
import { SettingsComponent } from './global/settings/settings.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'chef-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    ErrorBannerComponent,
    FooterComponent,
    HeaderComponent,
    NavigationFiltersComponent,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    NgClass,
    RouterOutlet,
    SettingsComponent,
  ],
})
export class AppComponent {
  availableSections$ = this.ngrxStore.select(selectSections);
  availableServices$ = this.ngrxStore.select(selectServiceList);
  globalError$ = this.ngrxStore.select(selectGlobalError);
  isKdsUser$ = this.ngrxStore.select(selectIsKdsUser);
  isLoggedIn$ = this.ngrxStore.select(selectIsLogged);
  kdsSettings$ = this.ngrxStore.select(selectKdsSettings);
  sectionsDisplayed$ = this.ngrxStore.select(selectSectionsDisplayed);
  selectIsPrinterPaired$ = this.ngrxStore.select(selectIsPrinterPaired);
  totalReservationsCount$ = this.ngrxStore.select(
    selectTotalReservationsTickets,
  );
  totalOrdersCount$ = this.ngrxStore.select(selectTotalOrderTickets);
  totalFiredCount$ = this.ngrxStore.select(selectTotalFiredTickets);
  totalDoneCount$ = this.ngrxStore.select(selectTotalDoneTickets);
  user$ = this.ngrxStore.select(selectUser);
  printerName$ = this.ngrxStore.select(selectPrinter);
  printerConnected$ = this.ngrxStore.select(selectIsPrinterConnected);

  constructor(private ngrxStore: Store<State>) {}
}
