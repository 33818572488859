import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SectionTicket, ServiceStatus } from 'src/app/models/tickets';
import { State } from 'src/app/reducers';
import { setSectionsDisplayed } from 'src/app/reducers/sections/sections.actions';
import {
  isAvailableSectionsLoading,
  selectCurrentServiceStatus,
  selectSections,
  selectSectionsDisplayed,
  selectServiceList,
} from 'src/app/reducers/sections/sections.selectors';
import { selectIsPrinterConnected } from 'src/app/reducers/shared/shared.selectors';
import {
  changeReservationStatus,
  deleteTicket,
  fetchAllSectionTickets,
  fetchSectionTickets,
  fetchSectionTicketsByUrl,
  resetReservations,
  undoTicketStatus,
  updateTicketStatus,
} from 'src/app/reducers/tickets/tickets.actions';
import { selectKdsSettings } from 'src/app/reducers/user/user.selectors';

@Component({ template: '' })
export abstract class PageTicketsComponent {
  availableSections$ = this.ngrxStore.select(selectSections);
  availableServices$ = this.ngrxStore.select(selectServiceList);
  isLoading$ = this.ngrxStore.select(isAvailableSectionsLoading);
  kdsSettings$ = this.ngrxStore.select(selectKdsSettings);
  printerConnected$ = this.ngrxStore.select(selectIsPrinterConnected);
  sectionsDisplayed$ = this.ngrxStore.select(selectSectionsDisplayed);
  serviceStatus$ = this.ngrxStore.select(selectCurrentServiceStatus);

  constructor(protected ngrxStore: Store<State>) {}

  pageClick(
    service: ServiceStatus,
    data: {
      pageNumber: number;
      url: string;
      key: string;
      previousUrl: string;
      pageSize: number;
      showLoading: boolean;
    },
  ): void {
    if (!(data.url || data.previousUrl)) {
      this.ngrxStore.dispatch(
        fetchSectionTickets({
          page_size: data.pageSize,
          section: data.key,
          service_status: service,
          page: data.pageNumber + 1,
        }),
      );
    } else {
      console.log(data);
      const href = new URL(data.url ? data.url : data.previousUrl);
      href.searchParams.set('page', `${data.pageNumber + 1}`);
      this.ngrxStore.dispatch(
        fetchSectionTicketsByUrl({
          url: href.toString(),
          page: data.pageNumber + 1,
          section: data.key,
          service_status: service,
          showLoading: data.showLoading,
        }),
      );
    }
  }

  updateTicketStatus(
    status: ServiceStatus,
    data: { ids: number[]; section: string; ticket?: SectionTicket },
  ) {
    if (status === ServiceStatus.RESERVED && data.ticket) {
      this.ngrxStore.dispatch(
        changeReservationStatus({
          ids: data.ids,
          data: {
            consumers: data.ticket.consumer ? [data.ticket.consumer] : [],
            users: data.ticket.consumer ? [] : [data.ticket.created_by],
            table_number: data.ticket.table_number ?? '',
          },
        }),
      );
    } else {
      this.ngrxStore.dispatch(
        updateTicketStatus({
          ids: data.ids,
          data: { service_status: status + 1 },
          section: data.section,
        }),
      );
    }
  }

  undoTicketStatus(
    status: ServiceStatus,
    data: { ids: number[]; section: string; ticket?: SectionTicket },
  ) {
    if (status === ServiceStatus.ORDERED && data.ticket) {
      this.ngrxStore.dispatch(
        resetReservations({
          ids: data.ids,
          data: {
            consumers: data.ticket.consumer ? [data.ticket.consumer] : [],
            users: data.ticket.consumer ? [] : [data.ticket.created_by],
          },
        }),
      );
    } else {
      this.ngrxStore.dispatch(
        undoTicketStatus({
          ids: data.ids,
          data: { service_status: status - 1 },
          section: data.section,
        }),
      );
    }
  }

  deleteTicket(
    status: ServiceStatus,
    data: { ids: number[]; section: string },
  ) {
    this.ngrxStore.dispatch(
      deleteTicket({
        ids: data.ids,
        data: { service_status: status },
        section: data.section,
        count: 1,
      }),
    );
  }

  showAllSections() {
    this.ngrxStore.dispatch(setSectionsDisplayed({ sections: [] }));
    this.ngrxStore.dispatch(fetchAllSectionTickets({}));
  }
}
