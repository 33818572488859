import { Routes } from '@angular/router';
import { DoneComponent } from './containers/done/done.component';
import { doneResolver } from './services/resolvers/done.resolver';
import { FiredComponent } from './containers/fired/fired.component';
import { firedResolver } from './services/resolvers/fired.resolver';
import { LoginComponent } from './authentication/login/login.component';
import { loginGuard } from './services/guards/login.guard';
import { OrdersComponent } from './containers/order/orders.component';
import { ordersResolver } from './services/resolvers/orders.resolver';
import { ReservationsComponent } from './containers/reservation/reservations.component';
import { reservationsResolver } from './services/resolvers/reservations.resolver';
import { kdsGuard } from './services/guards/kds.guard';
import { AccessForbiddenComponent } from './authentication/access-forbidden/access-forbidden.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginGuard],
  },
  {
    path: 'forbidden',
    component: AccessForbiddenComponent,
    canActivate: [loginGuard],
  },
  {
    path: 'fired',
    component: FiredComponent,
    canActivate: [loginGuard, kdsGuard],
    resolve: { fired: firedResolver },
  },
  {
    path: 'reservations',
    component: ReservationsComponent,
    canActivate: [loginGuard, kdsGuard],
    resolve: { reservations: reservationsResolver },
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [loginGuard, kdsGuard],
    resolve: { orders: ordersResolver },
  },
  {
    path: 'done',
    component: DoneComponent,
    canActivate: [loginGuard, kdsGuard],
    resolve: { done: doneResolver },
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];
