<div class="header">
  <img class="chef-img" src="assets/img/chef.png" alt="Chef" />
  <h1>{{ 'login.welcome' | transloco }}</h1>
</div>
<mat-card class="card mat-elevation-z2">
  <chef-form-with-errors
    chefServerFormError
    class="form"
    [formGroup]="loginForm"
    formId="loginForm"
  >
    <div class="fields">
      <chef-field-with-errors
        icon="email"
        type="email"
        [label]="'registration.email.placeholder' | transloco"
        translationKey="registration.email.errors"
        formControlName="email"
        [required]="true"
      ></chef-field-with-errors>
      <chef-field-with-errors
        icon="lock_outline"
        type="password"
        [label]="'registration.password1.placeholder' | transloco"
        formControlName="password"
        translationKey="registration.password1.errors"
        [required]="true"
      ></chef-field-with-errors>
    </div>
    <button
      class="submit-button"
      mat-flat-button
      color="primary"
      type="submit"
      (click)="onSubmit()"
      [disabled]="spinnerState$ | async"
    >
      @if (spinnerState$ | async) {
        <span>
          <mat-spinner
            class="loading-spinner"
            mode="indeterminate"
            [diameter]="24"
          ></mat-spinner>
        </span>
      }
      {{ 'login.login-button' | transloco }}
    </button>
  </chef-form-with-errors>
  <div class="links remind-password">
    <a
      href="https://menu.menutech.com/reset-password"
      class="remind-password"
      target="_blank"
      >{{ 'login.remind-password' | transloco }}</a
    >
  </div>
</mat-card>
<div class="info">
  <mat-icon class="info-icon">info</mat-icon>
  <div class="info-text">
    {{ 'login.use' | transloco }}
    <a
      href="https://menu.menutech.com/"
      class="menutech-link"
      target="_blank"
      >{{ 'login.menutech' | transloco }}</a
    >
    {{ 'login.access-credentials' | transloco }}
  </div>
</div>
