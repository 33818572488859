import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { FieldErrors, NonFieldError } from 'src/app/models/authentication';

export const showSnackbarMessage = createAction(
  `[Global] Show snackbar message, it can be an error or a normal message`,
  props<{
    errorCode?: number;
    message?: string;
    snackClass?: string;
    button?: string;
    duration?: number;
  }>(),
);

export const handleHttpError = createAction(
  `[API] Handle HTTP error returned from the server`,
  props<{
    error: HttpErrorResponse;
    formId?: string;
    forceSnackbar?: boolean;
  }>(),
);

export const setFieldErrors = createAction(
  `[API] Set field errors locally`,
  props<{ payload: FieldErrors }>(),
);

export const setFormErrors = createAction(
  `[API] Set form error to the list of non_field_errors locally`,
  props<{ error: NonFieldError }>(),
);

export const setGlobalError = createAction(
  `[API] Set global error locally`,
  props<{ error: string }>(),
);

export const clearFormAndFieldErrors = createAction(
  `[Login] Clear form errors locally`,
  props<{ payload: string }>(),
);

export const setPairedPrinter = createAction(
  `[Printer] Set paired printer`,
  props<{ payload: string | null }>(),
);

export const setPrinterConnectionStatus = createAction(
  `[Printer] Set printer connection status`,
  props<{ connection: boolean }>(),
);
