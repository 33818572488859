import { createSelector } from '@ngrx/store';

import { State } from '..';

export const selectState = (state: State) => state.user;

export const selectUser = createSelector(
  selectState,
  (state) => state?.currentUser,
);

export const selectIsLogged = createSelector(
  selectState,
  (state) => state?.isLogged,
);

export const selectLoginSpinnerState = createSelector(
  selectState,
  (state) => state?.loading,
);

export const selectUserLocation = createSelector(
  selectState,
  (state) => state?.currentUser?.location?.id,
);

export const selectIsKdsUser = createSelector(
  selectState,
  (state) => state?.currentUser?.is_kds,
);

export const selectKdsSettings = createSelector(
  selectState,
  (state) => state?.kds_settings,
);

export const selectPageSize = createSelector(
  selectState,
  (state) => state?.kds_settings?.display_page_size ?? 10,
);

export const selectConnectPrinter = createSelector(
  selectState,
  (state) => state?.kds_settings?.connect_printer ?? false,
);

export const selectLanguage = createSelector(
  selectState,
  (state) => state?.currentUser?.settings?.language ?? 'en',
);
