import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { TranslocoService } from '@ngneat/transloco';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  EMPTY,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { LoginForm } from 'src/app/models/authentication';
import { DeepPartial } from 'src/app/models/generics';
import { KdsSettings, LoginSuccess, User } from 'src/app/models/user';
import { State } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { GenericsService } from 'src/app/services/generics/generics.service';
import { UserService } from 'src/app/services/user/user.service';
import { getFormattedDate } from 'src/app/shared/utils.functions';

import { connectPrinter } from '../printer/printer.actions';
import {
  clearAllSections,
  fetchServicesList,
  setSectionsDisplayed,
} from '../sections/sections.actions';
import {
  handleHttpError,
  setGlobalError,
  showSnackbarMessage,
} from '../shared/shared.actions';
import { WebsocketService } from './../../services/websocket/websocket.service';
import * as UserAction from './user.actions';
import { selectUser } from './user.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import {
  clearAllSectionTickets,
  processWebsocketsData,
} from '../tickets/tickets.actions';

@Injectable()
export class UserEffects {
  afterEffectsInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      mergeMap(() => {
        this.matIconRegistry.setDefaultFontSetClass(
          'material-symbols-outlined',
        );
        const token = this.userService.getToken();
        return [
          UserAction.setLogin({ isLogged: !!token }),
          UserAction.restoreUserLocally(),
          ...(token ? [UserAction.fetchUserDetail()] : []),
        ];
      }),
      catchError(() => EMPTY),
    ),
  );

  restoreUserLocally$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.restoreUserLocally),
      switchMap(() => {
        try {
          const user = this.userService.getStoredUser();
          return [UserAction.setUser({ payload: user })];
        } catch {
          return EMPTY;
        }
      }),
      catchError(() => EMPTY),
    ),
  );

  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.loginUser),
      tap(() => {
        this.ngrxStore.dispatch(UserAction.setLoginSpinner({ loading: true }));
      }),
      switchMap(({ email, password }) =>
        this.genericService
          .post<LoginForm, LoginSuccess>(this.configService.login, {
            email,
            password,
            kds: true,
          })
          .pipe(
            mergeMap((successData) => {
              this.userService.saveToken(successData);
              this.router.navigate(['fired']);
              return [
                UserAction.fetchUserDetail(),
                UserAction.setLogin({ isLogged: true }),
                UserAction.setLoginSpinner({ loading: false }),
              ];
            }),
            catchError((error: unknown) => [
              UserAction.setLoginSpinner({ loading: false }),
              handleHttpError({
                error: error as HttpErrorResponse,
                formId: 'loginForm',
              }),
            ]),
          ),
      ),
    ),
  );

  logoutUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.logoutUser),
      tap(() => {
        this.userService.removeToken();
      }),
      switchMap(() => [
        clearAllSectionTickets(),
        clearAllSections(),
        UserAction.clearUser(),
        UserAction.setLogin({ isLogged: false }),
        UserAction.removeUserLocally(),
        UserAction.disconnectWebSocket(),
        UserAction.redirectAfterLoggingOut(),
      ]),
      catchError((error: unknown) => [
        handleHttpError({ error: error as HttpErrorResponse }),
      ]),
    ),
  );

  redirectAfterLoggingOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAction.redirectAfterLoggingOut),
        switchMap(() => {
          return this.router.navigate(['login']);
        }),
      ),
    {
      dispatch: false,
    },
  );

  removeUserLocally$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAction.removeUserLocally),
        mergeMap(() => {
          this.userService.removeFromLocalStorage('user');
          return EMPTY;
        }),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  fetchUserDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.fetchUserDetail),
      switchMap(() =>
        this.genericService.get<User>(this.configService.user).pipe(
          tap((user) => {
            this.translationService.setActiveLang(user?.settings.language);
            this.userService.saveUserLocally(user);
          }),
          mergeMap((user) => [
            UserAction.setUser({ payload: user }),
            UserAction.setKdsSettings({
              kdsSettings: user.kds,
            }),
            UserAction.connectWebSocket(),
            fetchServicesList({ date: getFormattedDate() }),
            setSectionsDisplayed({
              sections: user.kds?.sections ? user.kds?.sections : [],
            }),
            ...(user.kds?.connect_printer
              ? [connectPrinter({ openFindPrinter: true })]
              : []),
          ]),
          catchError((error: unknown) => [
            handleHttpError({ error: error as HttpErrorResponse }),
          ]),
        ),
      ),
    ),
  );

  changeKdsSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.changeKdsSettings),
      withLatestFrom(this.ngrxStore.select(selectUser)),
      switchMap(([{ kdsSettings }, user]) =>
        this.genericService
          .patch<
            { kds: Partial<KdsSettings> },
            User
          >(`${this.configService.userDetail}${user?.id}/`, { kds: kdsSettings })
          .pipe(
            mergeMap((user) => [
              UserAction.setKdsSettings({
                kdsSettings: {
                  auto_print: user.kds?.auto_print ?? false,
                  show_consumer_name: user.kds?.show_consumer_name ?? false,
                  show_consumer_room: user.kds?.show_consumer_room ?? false,
                  show_consumer_diets: user.kds?.show_consumer_diets ?? false,
                  show_consumer_type: user.kds?.show_consumer_type ?? false,
                  show_consumer_profile:
                    user.kds?.show_consumer_profile ?? false,
                  show_order_time: user.kds?.show_order_time ?? false,
                  show_order_table: user.kds?.show_order_table ?? false,
                  show_order_waiting: user.kds?.show_order_waiting ?? false,
                  display_text_size: user.kds?.display_text_size ?? 100,
                  display_page_size: user.kds?.display_page_size ?? 10,
                  display_refresh_individually:
                    user.kds?.display_refresh_individually ?? false,
                  connect_printer: user.kds?.connect_printer ?? false,
                },
              }),
              showSnackbarMessage({
                message: this.translationService.translate(this.SAVE_CHANGES),
                button: this.translationService.translate(this.GOT_IT),
              }),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          ),
      ),
    ),
  );

  saveSectionsSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.saveSectionsSelection),
      withLatestFrom(this.ngrxStore.select(selectUser)),
      switchMap(([{ sections }, user]) =>
        this.genericService
          .patch<DeepPartial<User>, User>((user as User).url, {
            kds: { sections },
          })
          .pipe(
            mergeMap((user) => [
              setSectionsDisplayed({
                sections: user.kds?.sections ? user.kds?.sections : [],
              }),
              showSnackbarMessage({
                message: this.translationService.translate(this.SAVE_CHANGES),
                button: this.translationService.translate(this.GOT_IT),
              }),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          ),
      ),
    ),
  );

  connectWebSocket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.connectWebSocket),
      withLatestFrom(this.ngrxStore.select(selectUser)),
      switchMap(([, user]) => {
        this.websockets.connect(`${user!.organisation.identifier}_tickets`);
        return this.websockets.messagesSubject$;
      }),
      mergeMap((message) => [processWebsocketsData({ message })]),
    ),
  );

  disconnectWebSocket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserAction.disconnectWebSocket),
        switchMap(() => {
          this.websockets.disconnect();
          return [];
        }),
      ),
    { dispatch: false },
  );

  reportWebSocketError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.reportWebSocketError),
      switchMap(() => [
        setGlobalError({
          error: this.translationService.translate(
            'shared.errors.websockets-error',
          ),
        }),
      ]),
    ),
  );

  readonly SAVE_CHANGES = 'settings.changes-saved';
  readonly GOT_IT = 'settings.got-it';

  constructor(
    private actions$: Actions,
    private ngrxStore: Store<State>,
    private userService: UserService,
    private configService: ConfigService,
    private genericService: GenericsService,
    private matIconRegistry: MatIconRegistry,
    private router: Router,
    private translationService: TranslocoService,
    private websockets: WebsocketService,
  ) {}
}
