import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { State } from 'src/app/reducers';
import { selectCurrentService } from 'src/app/reducers/sections/sections.selectors';
import { fetchAllSectionTickets } from 'src/app/reducers/tickets/tickets.actions';
import {
  selectOrdersTicketsSectionCount,
  selectOrdersTickets,
} from 'src/app/reducers/tickets/tickets.selectors';
import { ClockService } from 'src/app/services/clock/clock.service';
import { PageTicketsComponent } from 'src/app/shared/components/page-tickets/page-tickets.component';
import { TranslocoPipe } from '@ngneat/transloco';
import { AsyncPipe } from '@angular/common';
import { SectionPanelsComponent } from '../../shared/components/section-panels/section-panels.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SectionTicket } from 'src/app/models/tickets';
import { printTicket } from 'src/app/reducers/printer/printer.actions';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'chef-orders',
  templateUrl: './orders.component.html',
  styleUrls: [
    '../../shared/components/page-tickets/page-tickets.component.scss',
    './orders.component.scss',
  ],
  standalone: true,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    SectionPanelsComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class OrdersComponent extends PageTicketsComponent implements OnInit {
  clock$!: Observable<Date>;
  tickets$ = this.ngrxStore.select(selectOrdersTickets);
  ticketsSectionCount$ = this.ngrxStore.select(selectOrdersTicketsSectionCount);

  constructor(
    private clockService: ClockService,
    protected override ngrxStore: Store<State>,
  ) {
    super(ngrxStore);
  }

  ngOnInit(): void {
    this.clock$ = this.clockService.getClock();
    this.ngrxStore
      .select(selectCurrentService)
      .pipe(take(1))
      .subscribe((val) => {
        if (val !== undefined)
          this.ngrxStore.dispatch(
            fetchAllSectionTickets({ service_status: 2 }),
          );
      });
  }

  printTicket(ticket: SectionTicket) {
    this.ngrxStore.dispatch(printTicket({ ticket }));
  }
}
