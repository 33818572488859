import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs';
import { selectIsKdsUser } from 'src/app/reducers/user/user.selectors';

export const kdsGuard: CanActivateFn = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _route: ActivatedRouteSnapshot,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const store = inject(Store);

  return store.pipe(
    select(selectIsKdsUser),
    map((val) => {
      if (val === false) {
        router.navigate(['forbidden']);
        return false;
      }
      return true;
    }),
  );
};
