import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { getBrowserLang } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import {
  BehaviorSubject,
  catchError,
  filter,
  Observable,
  of,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { State } from 'src/app/reducers';
import { showSnackbarMessage } from 'src/app/reducers/shared/shared.actions';
import { logoutUser } from 'src/app/reducers/user/user.actions';
import { selectLanguage } from 'src/app/reducers/user/user.selectors';
import { ConfigService } from 'src/app/services/config/config.service';

import { GenericsService } from './../generics/generics.service';
import { UserService } from './../user/user.service';
import { environment } from 'src/environments/environment';

/**
 * Function to clone the request and set the headers
 * @param req original request to clone
 * @param lang the language to set in the headers
 * @returns clone of the request with the headers set
 */
const cloneRequest = (
  req: HttpRequest<unknown>,
  lang: string,
): HttpRequest<unknown> => {
  const token = localStorage.getItem(environment.accessTokenKey);
  req = req.clone({
    setHeaders: {
      'Accept-Language': lang ? lang : 'en',
      'ngsw-bypass': 'true',
    },
  });

  if (token && req?.responseType !== 'text') {
    return req.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }
  return req;
};

/**
 * Function to intercept the http requests and add the headers, refresh the token if needed and handle errors
 * @param req original request to clone
 * @param next the next handler
 * @returns the next handler with the headers set
 */
export const authInterceptor: HttpInterceptorFn = (
  req,
  next,
): Observable<HttpEvent<unknown>> => {
  let isRefreshing = false;
  let lang = getBrowserLang() as string;
  const refreshTokenSubject = new BehaviorSubject<string | null>(null);

  const configService = inject(ConfigService);
  const genericService = inject(GenericsService);
  const userService = inject(UserService);

  // Store subscription
  const store: Store<State> = inject(Store);
  store
    .pipe(
      select(selectLanguage),
      filter((l) => !!l),
    )
    .subscribe((l) => (lang = l as string));

  const handleServerError = (error: unknown): void => {
    if (
      error instanceof HttpErrorResponse &&
      [0, 500, 502].includes(error?.status)
    ) {
      store.dispatch(showSnackbarMessage({ errorCode: error?.status }));
    }
  };

  const refreshToken = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
    refresh: string,
  ) => {
    return genericService
      .post<
        { refresh: string },
        { access: string; refresh: string }
      >(configService.refreshTokenEP, { refresh })
      .pipe(
        switchMap(({ access, refresh }) => {
          userService.saveAccessToken(access, refresh);
          isRefreshing = false;
          refreshTokenSubject.next(refresh);
          return next(cloneRequest(req, lang)).pipe(
            catchError((error: unknown) => {
              handleServerError(error);
              return throwError(() => new HttpErrorResponse(error as any));
            }),
          );
        }),
        catchError((error: unknown) => {
          if ((error as HttpErrorResponse)?.status === 401) {
            store.dispatch(logoutUser());
            return of<HttpEvent<unknown>>(
              null as unknown as HttpEvent<unknown>,
            ); // Return a null observable to stop the chain
          }
          return throwError(() => new HttpErrorResponse({ error }));
        }),
      );
  };

  const handle401Error = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
    refresh: string,
  ) => {
    if (isRefreshing) {
      return refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => {
          return next(cloneRequest(req, lang));
        }),
      );
    }
    isRefreshing = true;
    refreshTokenSubject.next(null);
    return refreshToken(req, next, refresh);
  };

  return next(cloneRequest(req, lang)).pipe(
    catchError((error: unknown) => {
      const httpError = error as HttpErrorResponse;
      if (httpError?.status === 401) {
        const refreshToken = localStorage.getItem(environment.refreshTokenKey);
        if (refreshToken && !req.url.includes('/token/refresh/')) {
          return handle401Error(req, next, refreshToken);
        }
        store.dispatch(logoutUser());
        return of<HttpEvent<unknown>>(null as unknown as HttpEvent<unknown>);
      }
      handleServerError(error);
      return throwError(() => new HttpErrorResponse({ error }));
    }),
  );
};
