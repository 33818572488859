import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public orders: string;
  public login: string;
  public refreshTokenEP: string;
  public search: string;
  public updateTicketEP: string;
  public deleteTicketEP: string;
  public user: string;
  public userDetail: string;
  public updateConsumerService: string;
  public deleteConsumerService: string;

  constructor() {
    this.orders = environment.apiUrl + '/orders/tickets/';
    this.login = environment.apiUrl + '/rest-auth/login/';
    this.refreshTokenEP = environment.apiUrl + '/rest-auth/token/refresh/';
    this.search = environment.apiUrl + '/orders/search/';
    this.updateTicketEP = environment.apiUrl + '/orders/update_multiple/';
    this.deleteTicketEP = environment.apiUrl + '/orders/delete_multiple/';
    this.user = environment.apiUrl + '/users/me/';
    this.userDetail = environment.apiUrl + '/users/';
    this.updateConsumerService =
      environment.apiUrl + '/consumer_services/upsert_multiple/';
    this.deleteConsumerService =
      environment.apiUrl + '/consumer_services/delete_multiple/';
  }
}
