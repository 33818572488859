import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'chef-section-pagination',
  templateUrl: './section-pagination.component.html',
  styleUrls: ['./section-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class SectionPaginationComponent {
  @Input() currentPage = 1;
  @Input() numberOfPages = 1;
  @Output() pageClick = new EventEmitter<number>();
}
