import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { identity, pickBy } from 'lodash-es';
import {
  catchError,
  concatMap,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { Results } from 'src/app/models/generics';
import {
  ReservationDetail,
  SectionTicket,
  ServiceStatus,
  serviceStatuses,
  UpdateTicketParams,
} from 'src/app/models/tickets';
import {
  DeletedServiceStatus,
  WebsocketAction,
} from 'src/app/models/webservice';
import { State } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { GenericsService } from 'src/app/services/generics/generics.service';
import { getFormattedDate } from 'src/app/shared/utils.functions';

import {
  fetchServicesList,
  setAvailableSectionsLoading,
} from '../sections/sections.actions';
import {
  selectCurrentDate,
  selectCurrentService,
  selectSections,
  selectSectionsDisplayed,
} from '../sections/sections.selectors';
import { handleHttpError } from '../shared/shared.actions';
import { selectKdsSettings, selectUserLocation } from '../user/user.selectors';
import { selectPageSize } from './../user/user.selectors';
import {
  addSectionTicket,
  changeOrderStatus,
  changeReservationStatus,
  deleteTicket,
  deleteTicketLocally,
  fetchAllSectionTickets,
  fetchSectionTickets,
  fetchSectionTicketsByUrl,
  processWebsocketsData,
  resetReservations,
  setSectionTickets,
  setSectionTicketsLoading,
  setUndoTicketStatus,
  setUpdatedTicketStatus,
  setUpdatedTickets,
  undoTicketStatus,
  updateTicketStatus,
} from './tickets.actions';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { HttpErrorResponse } from '@angular/common/http';
import { selectSectionTickets } from './tickets.selectors';

@Injectable()
export class TicketsEffects {
  fetchSectionTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSectionTickets),
      withLatestFrom(
        this.ngrxStore.select(selectUserLocation),
        this.ngrxStore.select(selectCurrentDate),
        this.ngrxStore.select(selectCurrentService),
      ),
      tap(([{ section, service_status }]) => {
        this.ngrxStore.dispatch(
          setSectionTicketsLoading({ service_status, section, loading: true }),
        );
      }),
      concatMap(
        ([
          {
            section,
            service_status,
            page_size,
            item_baselang,
            table_number,
            name_room,
            page,
          },
          location,
          currentDate,
          service,
        ]) => {
          const date = currentDate ?? getFormattedDate();
          const params = pickBy(
            {
              page_size,
              date,
              ...(location ? { location } : {}),
              service_status,
              item_baselang,
              table_number,
              name_room,
              page,
            },
            identity,
          );
          // empty string values should also be sent for service and section using the magic value of '-'
          params['section_level2_baselang'] = service ? service : '-';
          params['section_level3_baselang'] = section ? section : '-';
          return this.genericService
            .get<Results<SectionTicket>>(this.configService.orders, params)
            .pipe(
              mergeMap((res) => {
                if (params['page']) res.currentPage = params['page'] as number;
                return [
                  setSectionTickets({
                    service_status,
                    payload: {
                      [section]: {
                        ...res,
                        numberOfPages: Math.ceil(res.count / page_size),
                      },
                    },
                  }),
                ];
              }),
              catchError((error: unknown) => [
                handleHttpError({ error: error as HttpErrorResponse }),
              ]),
            );
        },
      ),
    ),
  );

  updateTicketStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTicketStatus),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentDate),
        this.ngrxStore.select(selectCurrentService),
      ),
      switchMap(([{ ids, data, section }, currentDate, currentService]) =>
        this.genericService
          .patch<UpdateTicketParams, SectionTicket[]>(
            this.configService.updateTicketEP,
            {
              ids,
              data,
              return_orders: true,
              date: currentDate,
              service: currentService ?? '',
            },
          )
          .pipe(
            tap((tickets) => {
              this.websockets.sendMessage({
                date: currentDate ?? getFormattedDate(),
                service: currentService ?? '',
                data: {
                  ids,
                  items: tickets,
                  previous_status: data['service_status'] - 1,
                  new_status: data['service_status'],
                },
                action: WebsocketAction.UPDATE_STATUS,
              });
            }),
            mergeMap(() => [
              setUpdatedTicketStatus({
                ids: ids,
                service_status: (data['service_status'] - 1) as ServiceStatus,
                section,
              }),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          ),
      ),
    ),
  );

  undoTicketStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(undoTicketStatus),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentDate),
        this.ngrxStore.select(selectCurrentService),
      ),
      switchMap(([{ ids, data, section }, date, service]) =>
        this.genericService
          .patch<UpdateTicketParams, SectionTicket[]>(
            this.configService.updateTicketEP,
            {
              ids,
              data,
              return_orders: true,
              date,
              service,
            },
          )
          .pipe(
            tap((tickets) => {
              this.websockets.sendMessage({
                date: date ?? getFormattedDate(),
                service: service ?? '',
                data: {
                  ids,
                  items: tickets,
                  previous_status: data['service_status'] + 1,
                  new_status: data['service_status'],
                },
                action: WebsocketAction.UPDATE_STATUS,
              });
            }),
            mergeMap(() => [
              setUndoTicketStatus({
                ids: ids,
                service_status: (data['service_status'] + 1) as ServiceStatus,
                section,
              }),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          ),
      ),
    ),
  );

  deleteTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTicket),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentDate),
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectPageSize),
      ),
      switchMap(
        ([
          { ids, data, section, count },
          currentDate,
          currentService,
          pageSize,
        ]) =>
          this.genericService
            .post<UpdateTicketParams, SectionTicket>(
              this.configService.deleteTicketEP,
              {
                ids,
                data,
              },
            )
            .pipe(
              tap(() => {
                this.websockets.sendMessage({
                  date: currentDate ?? getFormattedDate(),
                  service: currentService ?? '',
                  data: {
                    ids,
                    service_status: data.service_status,
                    count,
                  },
                  action: WebsocketAction.DELETE,
                });
              }),
              withLatestFrom(
                this.ngrxStore.select(
                  selectSectionTickets(data.service_status, section),
                ),
              ),
              mergeMap(([, currentTickets]) => {
                const actions: Action<string>[] = [
                  deleteTicketLocally({
                    ids: ids,
                    service_status: data.service_status,
                    section,
                    pageSize,
                    count: 1,
                  }),
                ];
                if (
                  currentTickets.length > pageSize &&
                  currentTickets.length % pageSize === 1
                ) {
                  actions.push(
                    fetchSectionTickets({
                      page_size: pageSize,
                      section: section,
                      service_status: data.service_status,
                      page: 1,
                    }),
                  );
                }
                return [...actions];
              }),
              catchError((error: unknown) => [
                handleHttpError({ error: error as HttpErrorResponse }),
              ]),
            ),
      ),
    ),
  );

  fetchAllSectionTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllSectionTickets),
      withLatestFrom(
        this.ngrxStore.select(selectKdsSettings),
        this.ngrxStore.select(selectSections),
        this.ngrxStore.select(selectSectionsDisplayed),
      ),
      concatMap(
        ([
          { section, item_baselang, table_number, name_room, service_status },
          kdsSettings,
          availableSections,
          sectionsDisplayed,
        ]) => {
          const actions: Action<string>[] = [];
          (service_status ? [service_status] : serviceStatuses).forEach(
            (service_status) => {
              if (section !== undefined) {
                actions.push(
                  fetchSectionTickets({
                    page_size: kdsSettings?.display_page_size ?? 10,
                    section,
                    service_status,
                    item_baselang,
                    table_number,
                    name_room,
                  }),
                );
              } else {
                availableSections
                  .filter(
                    (sec) =>
                      !sectionsDisplayed?.length ||
                      sectionsDisplayed?.includes(sec),
                  )
                  .forEach((sec) => {
                    actions.push(
                      fetchSectionTickets({
                        page_size: kdsSettings?.display_page_size ?? 10,
                        section: sec,
                        service_status,
                        item_baselang,
                        table_number,
                        name_room,
                      }),
                    );
                  });
              }
            },
          );
          return actions;
        },
      ),
    ),
  );

  fetchSectionTicketsByUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSectionTicketsByUrl),
      withLatestFrom(this.ngrxStore.select(selectKdsSettings)),
      tap(([{ section, service_status, showLoading }]) => {
        if (showLoading) {
          this.ngrxStore.dispatch(
            setSectionTicketsLoading({
              service_status,
              section,
              loading: true,
            }),
          );
        }
      }),
      concatMap(([{ url, section, service_status, page }, kdsSettings]) => {
        return this.genericService.get<Results<SectionTicket>>(url).pipe(
          mergeMap((res) => {
            const pageSize = kdsSettings!.display_page_size;
            res['numberOfPages'] = Math.ceil(res.count / (pageSize ?? 1));
            res['isLoading'] = false;
            res['currentPage'] = page;
            return [
              setSectionTickets({
                service_status,
                payload: { [section]: res },
              }),
            ];
          }),
          catchError((error: unknown) => {
            const errorDetail = (error as any).error?.error?.detail;
            if (errorDetail === 'Invalid page.') {
              const newUrl = new URL(url);
              newUrl.searchParams.set('page', '1');
              return this.genericService
                .get<Results<SectionTicket>>(newUrl.toString())
                .pipe(
                  mergeMap((res) => {
                    const pageSize = kdsSettings!.display_page_size;
                    res['numberOfPages'] = Math.ceil(
                      res.count / (pageSize ?? 1),
                    );
                    res['isLoading'] = false;
                    res['currentPage'] = 1;
                    return [
                      setSectionTickets({
                        service_status,
                        payload: { [section]: res },
                      }),
                    ];
                  }),
                  catchError((error: unknown) => [
                    handleHttpError({ error: error as HttpErrorResponse }),
                    setAvailableSectionsLoading({
                      availableSectionsLoading: false,
                    }),
                  ]),
                );
            }
            return [
              handleHttpError({ error: error as HttpErrorResponse }),
              setAvailableSectionsLoading({
                availableSectionsLoading: false,
              }),
            ];
          }),
        );
      }),
    ),
  );

  processWebsocketsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processWebsocketsData),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
        this.ngrxStore.select(selectPageSize),
      ),
      concatMap(([{ message }, currentService, currentDate, pageSize]) => {
        if (
          message.date === (currentDate ?? getFormattedDate()) &&
          currentService === undefined
        ) {
          return [
            fetchServicesList({ date: currentDate ?? getFormattedDate() }),
          ];
        }
        if (
          message.date !== (currentDate ?? getFormattedDate()) ||
          message.service !== currentService
        )
          return [];
        const actions: Action<string>[] = [];
        if (message.action === WebsocketAction.RESET) {
          const data = message.data as {
            items: SectionTicket[];
            consumers: number[];
            users: number[];
          };
          const itemsByServiceStatus = data.items.reduce(
            (acc, item) => {
              if (!acc[item.service_status as number]) {
                acc[item.service_status as number] = [];
              }
              acc[item.service_status as number].push(item);
              return acc;
            },
            {} as { [key: number]: SectionTicket[] },
          );
          ([1, 2, 3, 4] as ServiceStatus[])
            .filter((k) => itemsByServiceStatus[k]?.length)
            .forEach((key) => {
              actions.push(
                changeOrderStatus({
                  ids: itemsByServiceStatus[key].flatMap((item) => item.ids),
                  previous_status: key,
                  new_status: 1,
                  page_size: pageSize,
                  items: itemsByServiceStatus[key],
                }),
              );
            });
        } else if (message.action === WebsocketAction.CREATE) {
          const data = message.data as SectionTicket[];
          for (const d of data) {
            actions.push(
              addSectionTicket({
                payload: d,
                page_size: pageSize,
              }),
            );
          }
        } else if (message.action === WebsocketAction.UPDATE_STATUS) {
          const data = message.data as {
            ids: number[];
            previous_status: 1 | 2 | 3 | 4;
            new_status: 1 | 2 | 3 | 4;
            items: SectionTicket[];
          };
          actions.push(changeOrderStatus({ ...data, page_size: pageSize }));
        } else if (message.action === WebsocketAction.UPDATE) {
          const data = message.data as {
            ids: number[];
            items: SectionTicket[];
          };
          for (const d of data.items) {
            actions.push(
              setUpdatedTickets({
                ids: d.ids,
                service_status: d.service_status,
                section: d.section,
                items: [d],
              }),
            );
          }
        } else if (message.action === WebsocketAction.DELETE) {
          const data = message.data as {
            ids: number[];
            service_status: ServiceStatus;
            count: number;
            section: string;
          };
          actions.push(
            deleteTicketLocally({
              ids: data.ids,
              service_status: data.service_status,
              section: data.section,
              count: data.count,
              pageSize,
            }),
          );
        } else if (message.action === WebsocketAction.RESET_DELETE) {
          const data = message.data as DeletedServiceStatus[];
          data.forEach((d) => {
            actions.push(
              fetchSectionTickets({
                page_size: pageSize,
                section: d.section,
                service_status: d.service_status,
              }),
            );
          });
        }
        return actions;
      }),
    ),
  );

  changeReservationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeReservationStatus),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
      ),
      mergeMap(([{ ids, data }, currentService, date]) => {
        const currentDate = date ?? getFormattedDate();
        const reservationData = {
          ...data,
          date: currentDate,
          service: currentService ?? '',
          return_orders: true,
        };
        return this.genericService
          .post<
            Partial<ReservationDetail>,
            SectionTicket[]
          >(this.configService.updateConsumerService, reservationData)
          .pipe(
            tap((orders) => {
              this.websockets.sendMessage({
                date: currentDate,
                service: currentService ?? '',
                data: {
                  consumers: data.consumers ?? [],
                  users: data.users ?? [],
                  table_number: data.table_number ?? '',
                },
                action: WebsocketAction.UPDPATE_RESERVATION,
              });
              this.websockets.sendMessage({
                date: currentDate,
                service: currentService ?? '',
                data: {
                  service_status: 1,
                  ids: orders.flatMap((ord) => ord.ids),
                  items: orders,
                },
                action: WebsocketAction.UPDATE,
              });
              this.websockets.sendMessage({
                date: currentDate,
                service: currentService ?? '',
                data: {
                  ids: orders.flatMap((ord) => ord.ids),
                  previous_status: 1,
                  new_status: 2,
                  items: orders,
                },
                action: WebsocketAction.UPDATE_STATUS,
              });
            }),
            mergeMap((orders) => {
              // group orders by section
              const ordersBySection = orders.reduce(
                (acc, order) => {
                  if (!acc[order.section]) {
                    acc[order.section] = [];
                  }
                  acc[order.section].push(order);
                  return acc;
                },
                {} as { [key: string]: SectionTicket[] },
              );
              const actions: Action<string>[] = [];
              Object.entries(ordersBySection).forEach(([section, orders]) => {
                actions.push(
                  setUpdatedTickets({
                    ids: orders.flatMap((ord) => ord.ids),
                    service_status: 1 as ServiceStatus,
                    section,
                    items: orders,
                  }),
                );
                actions.push(
                  setUpdatedTicketStatus({
                    ids: orders.flatMap((ord) => ord.ids),
                    service_status: 1 as ServiceStatus,
                    section,
                  }),
                );
              });
              return actions;
            }),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          );
      }),
    ),
  );

  clearReservationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetReservations),
      withLatestFrom(
        this.ngrxStore.select(selectCurrentService),
        this.ngrxStore.select(selectCurrentDate),
      ),
      switchMap(([{ data }, currentService, date]) => {
        const reservationData = Object.assign(
          {
            date: date ? date : getFormattedDate(),
            service: currentService ?? '',
            return_orders: true,
          },
          data,
        );
        return this.genericService
          .post<
            Partial<ReservationDetail>,
            SectionTicket[]
          >(this.configService.deleteConsumerService, reservationData)
          .pipe(
            mergeMap((res) => {
              // group orders by section
              const ordersBySection = res.reduce(
                (acc, order) => {
                  const key = `${order.section}-${order.service_status}`;
                  if (!acc[key]) {
                    acc[key] = [];
                  }
                  acc[key].push(order);
                  return acc;
                },
                {} as { [key: string]: SectionTicket[] },
              );
              const actions: Action<string>[] = [];
              Object.values(ordersBySection).forEach((orders) => {
                actions.push(
                  setUpdatedTickets({
                    ids: orders.flatMap((ord) => ord.ids),
                    service_status: orders[0].service_status,
                    section: orders[0].section,
                    items: orders,
                  }),
                );
                actions.push(
                  setUndoTicketStatus({
                    ids: orders.flatMap((ord) => ord.ids),
                    service_status: orders[0].service_status,
                    section: orders[0].section,
                  }),
                );
              });

              this.websockets.sendMessage({
                date: date ? date : getFormattedDate(),
                service: currentService ?? '',
                data: {
                  ids: res.flatMap((item) => item.ids),
                  items: res,
                  consumers: data.consumers ?? [],
                  users: data.users ?? [],
                },
                action: WebsocketAction.RESET,
              });
              return actions;
            }),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private ngrxStore: Store<State>,
    private configService: ConfigService,
    private genericService: GenericsService,
    private websockets: WebsocketService,
  ) {}
}
