import { createAction, props } from '@ngrx/store';
import { LoginForm, LoginState } from 'src/app/models/authentication';
import { KdsSettings, User } from 'src/app/models/user';

export const clearUser = createAction(`[User] Clear User`);

export const loginUser = createAction(
  `[Login] Attempt to login with LoginForm data`,
  props<LoginForm>(),
);

export const setLogin = createAction(
  `[Login] Set login state`,
  props<LoginState>(),
);

export const setUser = createAction(
  `[Login] Set user after Login`,
  props<{ payload: User | null }>(),
);

export const setLoginSpinner = createAction(
  `[Login] Set login spinner state`,
  props<{ loading: boolean }>(),
);

export const fetchUserDetail = createAction(`[Fetch User] Fetch user detail`);

export const logoutUser = createAction(
  `[Logging out completely] Clearing token`,
);

export const redirectAfterLoggingOut = createAction(
  `[Logging out completely] Redirect to the Login page after user was deleted`,
);

export const removeUserLocally = createAction(
  `[User API] Remove User locally after 401 error or logging out`,
);

export const restoreUserLocally = createAction(
  `[User FE action] Restore User from local storage`,
);

export const changeKdsSettings = createAction(
  `[Settings] Change KDS settings`,
  props<{ kdsSettings: Partial<KdsSettings> }>(),
);

export const setKdsSettings = createAction(
  `[Settings] Set KDS settings locally`,
  props<{ kdsSettings: Partial<KdsSettings> | null }>(),
);

export const saveSectionsSelection = createAction(
  `[Settings] Save sections selection`,
  props<{ sections: string[] | null }>(),
);

export const connectWebSocket = createAction(
  `[Websocket] Connect to websocket`,
);

export const disconnectWebSocket = createAction(
  `[Websocket] Disconnect from websocket`,
);

export const reportWebSocketError = createAction(
  `[Websocket] Connect to websocket error reporting`,
);
