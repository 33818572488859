import { inject } from '@angular/core';
import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { setServiceStatus } from 'src/app/reducers/sections/sections.actions';

export const reservationsResolver: ResolveFn<any> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _route: ActivatedRouteSnapshot,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _state: RouterStateSnapshot,
) => {
  const store = inject(Store);
  store.dispatch(setServiceStatus({ service_status: 1 }));
  return of(true);
};
