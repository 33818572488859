import { Action, createReducer, on } from '@ngrx/store';
import { ServiceStatus } from 'src/app/models/tickets';

import {
  clearAllSections,
  setAvailableSections,
  setAvailableSectionsLoading,
  setCurrentDate,
  setCurrentService,
  setSectionsDisplayed,
  setServicesList,
  setServiceStatus,
} from './sections.actions';

function getTodayDateString() {
  const today = new Date();
  const offset = today.getTimezoneOffset();
  return new Date(today.getTime() - offset * 60 * 1000)
    .toISOString()
    .split('T')[0];
}

export interface SectionState {
  services: string[];
  sections: string[];
  availableSectionsLoading: boolean;
  sectionsDisplayed: string[] | null;
  current_service: string | undefined;
  current_date: string;
  current_service_status: ServiceStatus | undefined;
}

export const initialState: SectionState = {
  services: [],
  sections: [],
  availableSectionsLoading: true,
  sectionsDisplayed: [],
  current_service: undefined,
  current_date: getTodayDateString(),
  current_service_status: undefined,
};

const _currentSectionReducer = createReducer(
  initialState,
  on(clearAllSections, () => ({ ...initialState })),
  on(setServicesList, (state, { services }) => ({ ...state, services })),
  on(setAvailableSections, (state, { sections }) => ({ ...state, sections })),
  on(setAvailableSectionsLoading, (state, { availableSectionsLoading }) => ({
    ...state,
    availableSectionsLoading,
  })),
  on(setSectionsDisplayed, (state, { sections }) => ({
    ...state,
    sectionsDisplayed: sections,
  })),
  on(setCurrentService, (state, { service }) => ({
    ...state,
    current_service: service,
  })),
  on(setCurrentDate, (state, { date }) => ({ ...state, current_date: date })),
  on(setServiceStatus, (state, { service_status }) => ({
    ...state,
    current_service_status: service_status,
  })),
);

export function sectionReducer(
  state: SectionState | undefined,
  action: Action,
) {
  return _currentSectionReducer(state, action);
}
