import { createAction, props } from '@ngrx/store';
import { ServiceStatus } from 'src/app/models/tickets';

export const fetchServicesList = createAction(
  `[Section Action] Fetch service list`,
  props<{ date: string }>(),
);

export const setServicesList = createAction(
  `[Section Action] Set user service list`,
  props<{ services: string[] }>(),
);

export const fetchAvailableSections = createAction(
  `[Section Action] Fetch available sections list`,
  props<{ currentService: string; date?: string }>(),
);

export const setAvailableSections = createAction(
  `[Section Action] Set available sections list`,
  props<{ sections: string[] }>(),
);

export const setAvailableSectionsLoading = createAction(
  `[Section Action] Set available sections loading`,
  props<{ availableSectionsLoading: boolean }>(),
);

export const setSectionsDisplayed = createAction(
  `[Settings] Set sections displayed`,
  props<{ sections: string[] | null }>(),
);

export const setCurrentService = createAction(
  `[Section Action] Set current service`,
  props<{ service: string }>(),
);

export const setCurrentDate = createAction(
  `[Section Action] Set current date`,
  props<{ date: string }>(),
);

export const setServiceStatus = createAction(
  `[Section Action] Set service status`,
  props<{ service_status: ServiceStatus }>(),
);

export const clearAllSections = createAction(
  `[Section Action] Clear all sections`,
);
