<div class="settings-header">
  <div class="current-user">
    <mat-icon>military_tech</mat-icon>
    <span class="name"
      >{{ user?.first_name }}
      <span class="name-last">{{ user?.last_name }}</span></span
    >
  </div>

  <button class="toggle-button" mat-button (click)="toggle.emit()">
    <mat-icon class="toggle-icon">menu_open</mat-icon>
  </button>
</div>

@if (sectionsFormArray.controls.length) {
  <h3 class="sidebar-title">
    {{ 'settings.sections-displayed.title' | transloco }}
  </h3>
  <form class="options" [formGroup]="form">
    @for (
      section of sectionsFormArray.controls;
      track section.value;
      let i = $index
    ) {
      <div formArrayName="section">
        <mat-checkbox
          color="primary"
          [name]="sections![i]"
          [class.italics]="!sections![i]"
          [formControlName]="i"
          (change)="checkboxClicked($event)"
        >
          {{
            sections![i]
              ? sections![i]
              : ('settings.sections-displayed.no-name' | transloco)
          }}
        </mat-checkbox>
      </div>
    }
  </form>
  <div class="action-items">
    <button mat-button class="underline" (click)="saveSectionChoice()">
      {{ 'settings.save-selection' | transloco }}
    </button>
    @if (isSectionChecked()) {
      <button mat-button (click)="showAllSections()" class="show-all underline">
        {{ 'settings.show-all' | transloco }}
      </button>
    }
  </div>
}

<h3 class="sidebar-title">
  {{ 'settings.shown-consumer-information.title' | transloco }}
</h3>
<form [formGroup]="form">
  <mat-checkbox
    class="checkbox"
    color="primary"
    name="show_consumer_name"
    (change)="
      consumerInfoChange({ name: $event.source.name, value: $event.checked })
    "
    formControlName="show_consumer_name"
  >
    {{ 'settings.shown-consumer-information.options.full-name' | transloco }}
  </mat-checkbox>
  <mat-checkbox
    class="checkbox"
    color="primary"
    name="show_consumer_room"
    (change)="
      consumerInfoChange({ name: $event.source.name, value: $event.checked })
    "
    formControlName="show_consumer_room"
  >
    {{ 'settings.shown-consumer-information.options.room-number' | transloco }}
  </mat-checkbox>

  @if (false) {
    <mat-checkbox
      class="checkbox"
      color="primary"
      name="show_consumer_diets"
      (change)="
        consumerInfoChange({ name: $event.source.name, value: $event.checked })
      "
      formControlName="show_consumer_diets"
    >
      {{
        'settings.shown-consumer-information.options.diet-consumer' | transloco
      }}
    </mat-checkbox>
  }
  <mat-checkbox
    class="checkbox"
    color="primary"
    name="show_consumer_type"
    (change)="
      consumerInfoChange({ name: $event.source.name, value: $event.checked })
    "
    formControlName="show_consumer_type"
  >
    {{
      'settings.shown-consumer-information.options.consumer-type' | transloco
    }}
  </mat-checkbox>
  <mat-checkbox
    class="checkbox"
    color="primary"
    name="show_consumer_profile"
    (change)="
      consumerInfoChange({ name: $event.source.name, value: $event.checked })
    "
    formControlName="show_consumer_profile"
  >
    {{
      'settings.shown-consumer-information.options.consumer-profile' | transloco
    }}
  </mat-checkbox>
</form>
<button mat-button class="underline" (click)="saveSettings()">
  {{ 'settings.save-selection' | transloco }}
</button>
<h3 class="sidebar-title">
  {{ 'settings.shown-order-information.title' | transloco }}
</h3>
<form [formGroup]="form">
  <div class="options">
    <mat-checkbox
      class="checkbox"
      color="primary"
      name="show_order_item"
      (change)="
        consumerInfoChange({ name: $event.source.name, value: $event.checked })
      "
      formControlName="show_order_item"
      >{{
        'settings.shown-order-information.options.item-name' | transloco
      }}</mat-checkbox
    >
    <mat-checkbox
      class="checkbox"
      color="primary"
      name="show_order_item_dateless"
      (change)="
        consumerInfoChange({ name: $event.source.name, value: $event.checked })
      "
      formControlName="show_order_item_dateless"
      >{{
        'settings.shown-order-information.options.item-name-dateless'
          | transloco
      }}</mat-checkbox
    >
    <mat-checkbox
      class="checkbox"
      color="primary"
      name="show_order_table"
      (change)="
        consumerInfoChange({ name: $event.source.name, value: $event.checked })
      "
      formControlName="show_order_table"
      >{{
        'settings.shown-order-information.options.table-number' | transloco
      }}</mat-checkbox
    >
    <mat-checkbox
      class="checkbox"
      color="primary"
      name="show_order_waiting"
      (change)="
        consumerInfoChange({ name: $event.source.name, value: $event.checked })
      "
      formControlName="show_order_waiting"
      >{{
        'settings.shown-order-information.options.waiting-time' | transloco
      }}</mat-checkbox
    >
    <mat-checkbox
      class="checkbox"
      color="primary"
      name="show_order_time"
      (change)="
        consumerInfoChange({ name: $event.source.name, value: $event.checked })
      "
      formControlName="show_order_time"
      >{{
        'settings.shown-order-information.options.order-time' | transloco
      }}</mat-checkbox
    >
    <button mat-button class="underline" (click)="saveSettings()">
      {{ 'settings.save-selection' | transloco }}
    </button>
  </div>
</form>
<h3 class="sidebar-title">
  {{ 'settings.display-setting.title' | transloco }}
</h3>
<div class="text-size">
  <mat-form-field appearance="fill" [formGroup]="form">
    <mat-label>{{ 'settings.select-text-size' | transloco }}</mat-label>
    <mat-select
      formControlName="display_text_size"
      [attr.name]="'display_text_size'"
      (selectionChange)="textSizeChanged($event.value)"
    >
      @for (size of textSizes; track size) {
        <mat-option [value]="size">
          {{ size }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
<div class="page-size">
  <mat-form-field appearance="fill" [formGroup]="form">
    <mat-label>{{ 'settings.select-page-size' | transloco }}</mat-label>
    <mat-select
      formControlName="display_page_size"
      [attr.name]="'display_page_size'"
      (selectionChange)="pageSizeChanges($event.value)"
    >
      @for (size of pageSizes; track size) {
        <mat-option [value]="size">
          {{ size }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
<div class="refresh">
  <p [formGroup]="form">
    <mat-slide-toggle
      class="checkbox"
      color="primary"
      name="display_refresh_individually"
      (change)="
        changePrintSettings({ name: $event.source.name, value: $event.checked })
      "
      formControlName="display_refresh_individually"
      >{{
        'settings.display-refresh-individually' | transloco
      }}</mat-slide-toggle
    >
  </p>
</div>
<button mat-button class="underline" (click)="saveSettings()">
  {{ 'settings.save-selection' | transloco }}
</button>
<h3 class="sidebar-title">
  {{ 'settings.thermal-printer-settings.title' | transloco }}
</h3>
<form class="printer-form" [formGroup]="form">
  <mat-slide-toggle
    class="checkbox"
    color="primary"
    name="connect_printer"
    (change)="
      changePrintSettings({ name: $event.source.name, value: $event.checked })
    "
    formControlName="connect_printer"
    >{{
      'settings.thermal-printer-settings.activate-printer' | transloco
    }}</mat-slide-toggle
  >
</form>

@if (kdsSettings?.connect_printer) {
  <div class="options">
    @if (printerPaired) {
      <span class="printer-name"
        ><mat-icon
          [ngClass]="printerConnected ? 'print-icon' : 'print-icon-red'"
          class="material-symbols-outlined"
          >{{ printerConnected ? 'print_disabled' : 'print' }}</mat-icon
        >
        {{ printerName }}</span
      >
      <button
        class="action-button"
        mat-button
        (click)="printerConnected ? disconnectDevice() : reconnectDevice()"
      >
        {{
          printerConnected
            ? ('settings.thermal-printer-settings.options.click-to-disconnect-printer'
              | transloco)
            : ('settings.thermal-printer-settings.options.click-to-reconnect-printer'
              | transloco)
        }}
      </button>
      <p class="printer-actions">
        <button
          [disabled]="!printerConnected"
          class="cut-page action-button printer-action-button"
          mat-flat-button
          (click)="printerCutPaper()"
        >
          {{ 'settings.thermal-printer-settings.options.cut-page' | transloco }}
        </button>
        <button
          [disabled]="!printerConnected"
          class="action-button printer-action-button"
          mat-flat-button
          (click)="printerFeedPaper()"
        >
          {{
            'settings.thermal-printer-settings.options.feed-paper' | transloco
          }}
        </button>
      </p>
      <form [formGroup]="form">
        <p>
          <mat-checkbox
            class="checkbox"
            color="primary"
            name="auto_print"
            (change)="
              changePrintSettings({
                name: $event.source.name,
                value: $event.checked,
              })
            "
            formControlName="auto_print"
            >{{
              'settings.thermal-printer-settings.options.print-when-ticket-marked-done'
                | transloco
            }}
          </mat-checkbox>
        </p>
      </form>
    } @else {
      <span class="no-printer">
        {{
          'settings.thermal-printer-settings.options.no-printer-connected'
            | transloco
        }}</span
      >
      <button
        class="underline action-button"
        mat-button
        (click)="printerFindDevices()"
      >
        {{
          'settings.thermal-printer-settings.options.click-to-connect-printer'
            | transloco
        }}
      </button>
    }
  </div>
}
