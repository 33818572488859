import { createSelector } from '@ngrx/store';

import { State } from '..';

export const selectState = (state: State) => state.shared;

export const selectAuthenticationErrors = createSelector(
  selectState,
  (state) => state?.form_errors,
);

export const selectPrinter = createSelector(
  selectState,
  (state) => state?.paired_printer,
);

export const selectIsPrinterPaired = createSelector(
  selectState,
  (state) => state?.paired_printer !== null,
);

export const selectIsPrinterConnected = createSelector(
  selectState,
  (state) => state?.printer_connection,
);

export const selectGlobalError = createSelector(
  selectState,
  (state) => state?.global_error,
);
