import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

type Count = number | undefined | null;

@Component({
  selector: 'chef-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    RouterLinkActive,
    RouterLink,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class HeaderComponent {
  @Input() totalReservationsCount: Count = 0;
  @Input() totalOrdersCount: Count = 0;
  @Input() totalFiredCount: Count = 0;
  @Input() totalDoneCount: Count = 0;
}
