export enum ServiceStatus {
  RESERVED = 1,
  ORDERED = 2,
  FIRED = 3,
  DONE = 4,
}

export const serviceStatuses = [1, 2, 3, 4] as const;

export const serviceStatusesNames = [
  'reservation',
  'ordered',
  'fired',
  'done',
] as const;

export type ServiceStatusName = (typeof serviceStatusesNames)[number];

export interface SectionTicketsParams {
  date: string;
  is_user: boolean;
  location: number;
  page_size: number;
  section_level2_baselang: string;
  section_level3_baselang: string;
  service_status: ServiceStatus;
}

export interface SectionTicket {
  id: number;
  ids: number[];
  menudish_id: number[];

  consumer: number;
  created_by: number;
  created_at: string;
  created_type: number;
  last_modified_by_name: string;
  last_modified_at: string;

  date: string;
  service: string;
  section: string;
  item: string[];
  item_option: number;
  description: string[];
  quantity: number[];
  portion_size: number[];
  count_orders: number;
  variants: string[];
  order_diets: string[];

  menu_has_date: boolean;
  menu_name: string;
  menu_source: string;

  name: string;
  vip: boolean;
  is_special_order: boolean;
  is_active: boolean;
  is_user: boolean;
  table_number: string;
  room: string;
  room_floor: string;
  type_name: string;
  custom_data: any;
  consumer_texture: number | null;
  consumer_consistency: number | null;
  consumer_portion_size: number;
  consumer_allergies: string[];
  consumer_intolerances: string[];
  diets: string[];
  has_birthday: boolean;
  consistency: (1 | 2 | 3 | 4 | null)[];
  texture: (1 | 2 | 3 | 4 | 5 | null)[];

  service_status: ServiceStatus;
  service_table_number: string;
  service_expedite: boolean;
  service_ordered_time: string | null;
  service_fired_time: string | null;
  service_done_time: string | null;
}

export interface MenuDetail {
  dates: string[];
  disabled: boolean;
  identifier: string;
  imported_at: string;
  location: Location;
  name: string;
  organisation: number;
  source: string;
}

export interface OrderVariant {
  id: number;
  item_baselang: string;
  item: string;
  price: number;
  variant_id: number;
}

export interface CustomData {
  [key: string]: string | number | boolean;
}

export interface UpdateTicketParams {
  ids: number[];
  data: { [key: string]: number };
  return_orders?: boolean;
  date?: string;
  service?: string;
}

export const ticketSortingOrder = {
  1: ['-vip', '-service_expedite', 'is_user', 'section_sorting', 'item_option'],
  2: [
    '-vip',
    '-service_expedite',
    'service_ordered_time',
    'is_user',
    'section_sorting',
    'item_option',
  ],
  3: [
    '-vip',
    '-service_expedite',
    'service_fired_time',
    'is_user',
    'section_sorting',
    'item_option',
  ],
  4: ['-service_done_time', 'section_sorting', 'item_option'],
};

export interface ReservationDetail {
  consumers: number[];
  users: number[];
  date: string;
  service: string;
  table_number: string;
  delete_orders: boolean;
}
