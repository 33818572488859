<button
  mat-tab-link
  class="nav-button reservations-button"
  mat-raised-button
  [routerLink]="'reservations'"
  routerLinkActive="active"
  queryParamsHandling="preserve"
>
  <mat-icon class="send">send</mat-icon>
  {{ 'shared.top-navbar.reservations' | transloco }}
  <span class="count">&nbsp;{{ totalReservationsCount }}</span>
</button>
<button
  mat-tab-link
  class="nav-button ordered-button"
  mat-raised-button
  [routerLink]="'orders'"
  routerLinkActive="active"
  queryParamsHandling="preserve"
>
  <mat-icon class="table">table_restaurant</mat-icon>
  {{ 'shared.top-navbar.orders' | transloco }}
  <span class="count">&nbsp;{{ totalOrdersCount }}</span>
</button>
<button
  mat-tab-link
  class="nav-button fired-button"
  mat-raised-button
  [routerLink]="'fired'"
  routerLinkActive="active"
  queryParamsHandling="preserve"
>
  <mat-icon class="bolt">bolt</mat-icon>
  {{ 'shared.top-navbar.fired' | transloco }}
  <span class="count">&nbsp;{{ totalFiredCount }}</span>
</button>
<button
  mat-tab-link
  class="nav-button done-button"
  mat-raised-button
  [routerLink]="'done'"
  routerLinkActive="active"
  queryParamsHandling="preserve"
>
  <mat-icon class="done">done</mat-icon>
  {{ 'shared.top-navbar.done' | transloco }}
  <span class="count">&nbsp;{{ totalDoneCount }}</span>
</button>
