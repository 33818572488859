import { createSelector } from '@ngrx/store';
import { State } from '..';

export const selectState = (state: State) => state.sections;

export const selectServiceList = createSelector(
  selectState,
  (state) => state?.services,
);

export const selectSections = createSelector(
  selectState,
  (state) => state?.sections,
);

export const isAvailableSectionsLoading = createSelector(
  selectState,
  (state) => state?.availableSectionsLoading,
);

export const selectSectionsDisplayed = createSelector(
  selectState,
  (state) => state?.sectionsDisplayed,
);

export const selectCurrentService = createSelector(
  selectState,
  (state) => state?.current_service,
);

export const selectCurrentDate = createSelector(
  selectState,
  (state) => state?.current_date,
);

export const selectCurrentServiceStatus = createSelector(
  selectState,
  (state) => state?.current_service_status,
);
