import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyValuePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ServerFormErrorDirective } from '../../directives/server-form-error.directive';

@Component({
  selector: 'chef-form-with-errors',
  templateUrl: './form-with-errors.component.html',
  styleUrls: ['./form-with-errors.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ServerFormErrorDirective,
    ReactiveFormsModule,
    MatFormFieldModule,
    KeyValuePipe,
  ],
})
export class FormWithErrorsComponent {
  @Input() formGroup!: FormGroup | null;
  @Input() formId!: string;
}
