import { createSelector } from '@ngrx/store';
import { getTotalCount } from 'src/app/shared/utils.functions';
import { State } from '..';
import { TicketsState } from './tickets.reducer';

export const selectState = (state: State) => state.tickets;

export const selectReservationsTickets = createSelector(
  selectState,
  (state) => state?.reservation,
);

export const selectReservationsTicketsSectionCount = createSelector(
  selectReservationsTickets,
  (tickets) => tickets && Object.keys(tickets).length,
);

export const selectOrdersTickets = createSelector(
  selectState,
  (state) => state?.ordered,
);

export const selectOrdersTicketsSectionCount = createSelector(
  selectOrdersTickets,
  (tickets) => tickets && Object.keys(tickets).length,
);

export const selectFiredTickets = createSelector(
  selectState,
  (state) => state?.fired,
);

export const selectFiredTicketsSectionCount = createSelector(
  selectFiredTickets,
  (tickets) => tickets && Object.keys(tickets).length,
);

export const selectDoneTickets = createSelector(
  selectState,
  (state) => state?.done,
);

export const selectDoneTicketsSectionCount = createSelector(
  selectDoneTickets,
  (tickets) => tickets && Object.keys(tickets).length,
);

export const selectTotalReservationsTickets = createSelector(
  selectState,
  (state) => getTotalCount(state?.reservation),
);

export const selectTotalOrderTickets = createSelector(selectState, (state) =>
  getTotalCount(state?.ordered),
);

export const selectTotalFiredTickets = createSelector(selectState, (state) =>
  getTotalCount(state?.fired),
);

export const selectTotalDoneTickets = createSelector(selectState, (state) =>
  getTotalCount(state?.done),
);

export const selectSectionTickets = (status: number, section: string) =>
  createSelector(selectState, (state) => {
    const statusMapper: { [key: number]: keyof TicketsState } = {
      1: 'reservation',
      2: 'ordered',
      3: 'fired',
      4: 'done',
    };
    return state[statusMapper[status]]?.[section]?.results ?? [];
  });
