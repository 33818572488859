@for (section of sections; track section) {
  <div class="section">
    <div class="section-header">
      <button mat-button class="section-name">
        {{ section }}
        ({{ tickets?.[section]?.results?.length }}/{{
          tickets?.[section]?.count
        }})
      </button>
      @if (serviceStatus !== 4) {
        <button
          mat-button
          class="section-action"
          (click)="
            completeAll(
              tickets?.[section]?.results,
              section,
              tickets?.[section]?.numberOfPages
            )
          "
        >
          {{ 'sections.complete-all.' + serviceStatus | transloco }} ({{
            tickets?.[section]?.results?.length
          }})
        </button>
      }
    </div>
    <div class="section-content">
      @if (!tickets?.[section] || tickets?.[section]?.isLoading) {
        <div class="sections-ticket-spinner">
          <mat-spinner></mat-spinner>
        </div>
      } @else {
        <div class="section-tickets">
          @for (ticket of tickets?.[section]?.results; track ticket.id) {
            <chef-ticket
              [clock]="clock"
              [kdsSettings]="kdsSettings"
              [serviceStatus]="serviceStatus"
              [printerConnected]="printerConnected"
              [id]="ticket.id"
              [ids]="ticket.ids"
              [vip]="ticket.vip"
              [customerName]="ticket.name"
              [consumerDiets]="ticket.diets"
              [customData]="ticket.custom_data"
              [consumerTexture]="ticket.consumer_texture"
              [consumerConsistency]="ticket.consumer_consistency"
              [consumerPortionSize]="ticket.consumer_portion_size"
              [consumerAllergies]="ticket.consumer_allergies"
              [consumerIntolerances]="ticket.consumer_intolerances"
              [hasBirthday]="ticket.has_birthday"
              [room]="ticket.room"
              [roomFloor]="ticket.room_floor"
              [type]="ticket.type_name"
              [quantity]="ticket.quantity"
              [texture]="ticket.texture"
              [consistency]="ticket.consistency"
              [portionSize]="ticket.portion_size"
              [item]="ticket.item"
              [itemOption]="ticket.item_option"
              [description]="ticket.description"
              [variants]="ticket.variants"
              [datedMenu]="ticket.menu_has_date"
              [diets]="ticket.order_diets"
              [description]="ticket.description"
              [expedite]="ticket.service_expedite"
              [ordered_time]="ticket.service_ordered_time"
              [fired_time]="ticket.service_fired_time"
              [done_time]="ticket.service_done_time"
              [tableNumber]="
                serviceStatus === 1
                  ? ticket.table_number
                  : ticket.service_table_number
              "
              [specialOrder]="ticket.is_special_order"
              (updateTicketStatusEvent)="
                changeTicketStatus(
                  false,
                  $event,
                  section,
                  tickets?.[section]?.results?.length,
                  tickets?.[section]?.numberOfPages,
                  ticket
                )
              "
              (undoTicketStatusEvent)="
                changeTicketStatus(
                  true,
                  $event,
                  section,
                  tickets?.[section]?.results?.length,
                  tickets?.[section]?.numberOfPages,
                  ticket
                )
              "
              (deleteTicketEvent)="
                deleteTicket(
                  $event,
                  section,
                  tickets?.[section]?.results?.length,
                  tickets?.[section]?.numberOfPages
                )
              "
              (printTicketEvent)="printSingleTicket.emit(ticket)"
            ></chef-ticket>
          }
        </div>
      }
    </div>
    <chef-section-pagination
      class="section-pagination"
      [currentPage]="tickets?.[section]?.currentPage ?? 1"
      [numberOfPages]="tickets?.[section]?.numberOfPages ?? 1"
      (pageClick)="changePage($event, section)"
    ></chef-section-pagination>
  </div>
}
