import { ServiceStatus } from './tickets';

export enum WebsocketAction {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  RESET = 'reset',
  RESET_DELETE = 'reset_delete',
  UPDATE_STATUS = 'update_status',
  UPDPATE_RESERVATION = 'update_reservation',
}

export interface WebsocketMessage {
  action: WebsocketAction;
  data: any;
  deviceId: string;
  date: string;
  service: string;
}

export interface DeletedServiceStatus {
  date: string;
  section: string;
  service: string;
  service_status: ServiceStatus;
  ids: number[];
}
