import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { of, switchMap, take } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectIsLogged } from 'src/app/reducers/user/user.selectors';

const authenticationRoutes = ['/login'];

export const loginGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const store = inject(Store);

  const isLogged$ = store.pipe(select(selectIsLogged), take(1));

  return isLogged$.pipe(
    switchMap((isLogged) => {
      if (authenticationRoutes.some((route) => state.url.startsWith(route))) {
        if (isLogged) {
          router.navigate(['fired'], {
            queryParams: state.root.queryParams,
          });
          return of(false);
        }
        return of(true);
      }
      if (!isLogged) {
        router.navigate(['login'], {});
        return of(false);
      }
      return of(isLogged);
    }),
  );
};
