@if (globalError$ | async) {
  <chef-error-banner
    class="notification"
    [error]="globalError$ | async"
  ></chef-error-banner>
}

@if ((isLoggedIn$ | async) && (isKdsUser$ | async)) {
  <mat-drawer-container class="drawer-container" [hasBackdrop]="true">
    <mat-drawer #drawer position="end" [mode]="'side'">
      @if ((kdsSettings$ | async) === null) {
        <div class="settings-spinner">
          <mat-spinner></mat-spinner>
        </div>
      } @else if ((isLoggedIn$ | async) && (isKdsUser$ | async)) {
        <chef-settings
          [kdsSettings]="(kdsSettings$ | async)!"
          [printerConnected]="printerConnected$ | async"
          [printerPaired]="(selectIsPrinterPaired$ | async) ?? false"
          [printerName]="printerName$ | async"
          [sections]="availableSections$ | async"
          [sectionsDisplayed]="sectionsDisplayed$ | async"
          [user]="user$ | async"
          (toggle)="drawer.toggle()"
        ></chef-settings>
      }
    </mat-drawer>
    <mat-drawer-content class="drawer-content">
      <main class="wrapper">
        <chef-navigation-filters
          [kdsSettings]="(kdsSettings$ | async)!"
          [language]="(user$ | async)?.settings?.language!"
          [serviceList]="availableServices$ | async"
        ></chef-navigation-filters>
        <button
          mat-button
          class="menu-toggle"
          [ngClass]="!drawer.opened ? 'toggle-button' : 'hide'"
          (click)="drawer.toggle()"
        >
          <mat-icon class="menu-icon">menu_open</mat-icon>
          @if (
            (kdsSettings$ | async)?.connect_printer &&
            (printerConnected$ | async) === false
          ) {
            <mat-icon class="print-icon-red"> print_disabled</mat-icon>
          }
        </button>
        <chef-header
          class="header"
          [totalReservationsCount]="totalReservationsCount$ | async"
          [totalOrdersCount]="totalOrdersCount$ | async"
          [totalFiredCount]="totalFiredCount$ | async"
          [totalDoneCount]="totalDoneCount$ | async"
        ></chef-header>
        <router-outlet></router-outlet>
      </main>
      <chef-footer
        class="footer"
        [showLogout]="(isLoggedIn$ | async) ?? false"
      ></chef-footer>
    </mat-drawer-content>
  </mat-drawer-container>
} @else {
  <main class="wrapper">
    <router-outlet></router-outlet>
  </main>
  <chef-footer
    class="footer"
    [showLogout]="(isLoggedIn$ | async) ?? false"
  ></chef-footer>
}
