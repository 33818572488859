import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { sectionReducer, SectionState } from './sections/sections.reducer';
import { sharedReducer, SharedState } from './shared/shared.reducer';
import { ticketsReducer, TicketsState } from './tickets/tickets.reducer';
import { userReducer, UserState } from './user/user.reducer';

export interface State {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router: RouterReducerState<any>;
  sections: SectionState;
  shared: SharedState;
  tickets: TicketsState;
  user: UserState;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
  sections: sectionReducer,
  shared: sharedReducer,
  tickets: ticketsReducer,
  user: userReducer,
};
