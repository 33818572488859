export interface LoginForm {
  email: string;
  password: string;
  kds?: boolean;
}

export interface LoginState {
  isLogged: boolean;
}

export const emailRegexPattern =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/;

export interface ApiFormErrors {
  [key: string]: string | string[] | undefined;
  non_field_errors?: string[];
}

export interface NonFieldError {
  form_id: string;
  message: string;
}

export interface FieldErrors {
  [key: string]: string;
}

export interface FormErrors {
  non_field_errors: NonFieldError[];
}
