<a href="https://menutech.com" target="_blank"
  ><img class="logo" src="assets/img/logo.svg" alt="Logo Menutech"
/></a>
<div class="footer-text">
  @if (showLogout) {
    <button mat-button (click)="goBackToOrderTaking()">
      <mat-icon>settings</mat-icon>
      {{ 'shared.footer.back-to-order-taking' | transloco }}
    </button>
  }
  @if (showLogout) {
    <button mat-button (click)="logOut()">
      <mat-icon>logout</mat-icon>
      {{ 'shared.footer.logout' | transloco }}
    </button>
  }
</div>
