import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { compact } from 'lodash-es';
import { catchError, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs';
import { State } from 'src/app/reducers';
import { ConfigService } from 'src/app/services/config/config.service';
import { GenericsService } from 'src/app/services/generics/generics.service';
import { getFormattedDate } from 'src/app/shared/utils.functions';

import { selectServiceParam } from '../router.selectors';
import { handleHttpError } from '../shared/shared.actions';
import { fetchAllSectionTickets } from '../tickets/tickets.actions';
import { selectUserLocation } from './../user/user.selectors';
import {
  fetchAvailableSections,
  fetchServicesList,
  setAvailableSections,
  setAvailableSectionsLoading,
  setCurrentService,
  setServicesList,
} from './sections.actions';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class SectionsEffects {
  fetchServicesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchServicesList),
      withLatestFrom(
        this.ngrxStore.select(selectServiceParam),
        this.ngrxStore.select(selectUserLocation),
      ),
      tap(() => {
        this.ngrxStore.dispatch(
          setAvailableSectionsLoading({ availableSectionsLoading: true }),
        );
      }),
      switchMap(([{ date }, serviceParam, location]) => {
        const params: {
          field: string;
          location?: number;
          date?: string;
        } = {
          field: 'section_level2',
        };
        if (location) params['location'] = location;
        params['date'] = date ? date : getFormattedDate();
        return this.genericService
          .get<{
            count: number;
            results: string[];
          }>(this.configService.search, params)
          .pipe(
            mergeMap((res: { count: number; results: string[] }) => {
              const selectedService =
                serviceParam && res.results.includes(serviceParam as string)
                  ? serviceParam
                  : compact(res.results)[0];
              if (
                !serviceParam ||
                !res.results.includes(serviceParam as string)
              ) {
                this.router.navigate([], {
                  relativeTo: this.activatedRoute,
                  queryParams: { service: selectedService },
                });
              }
              return [
                setCurrentService({ service: selectedService as string }),
                setServicesList({ services: res.results }),
                fetchAvailableSections({
                  currentService: selectedService as string,
                  date,
                }),
              ];
            }),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
            ]),
          );
      }),
    ),
  );

  fetchAvailableSections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAvailableSections),
      tap(() => {
        this.ngrxStore.dispatch(
          setAvailableSectionsLoading({ availableSectionsLoading: true }),
        );
      }),
      withLatestFrom(this.ngrxStore.select(selectUserLocation)),
      switchMap(([{ currentService, date }, location]) => {
        const params: {
          field: string;
          location?: number;
          extra_value?: string;
          date?: string;
        } = {
          field: 'section_level3',
        };
        if (location) params['location'] = location;
        if (currentService) params['extra_value'] = currentService;
        params['date'] = date ?? getFormattedDate();
        return this.genericService
          .get<{
            count: number;
            results: string[];
          }>(this.configService.search, params)
          .pipe(
            mergeMap((res: { count: number; results: string[] }) => [
              setAvailableSections({ sections: res.results }),
              setAvailableSectionsLoading({
                availableSectionsLoading: false,
              }),
              fetchAllSectionTickets({}),
            ]),
            catchError((error: unknown) => [
              handleHttpError({ error: error as HttpErrorResponse }),
              setAvailableSectionsLoading({ availableSectionsLoading: false }),
            ]),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private ngrxStore: Store<State>,
    private configService: ConfigService,
    private genericService: GenericsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}
}
