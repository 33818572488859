import { createAction, props } from '@ngrx/store';
import { SectionTicket } from 'src/app/models/tickets';

export const printTicket = createAction(
  `[Printer] Print ticket`,
  props<{ ticket: SectionTicket }>(),
);

export const findPrinter = createAction(
  `[Printer] Find printer and pair it if selected by the user`,
);

export const connectPrinter = createAction(
  `[Printer] Connect printer`,
  props<{ openFindPrinter?: boolean }>(),
);

export const disconnectPrinter = createAction(`[Printer] Disconnect printer`);

export const cutPaper = createAction(`[Printer] Cut page`);

export const feedPaper = createAction(`[Printer] Feed paper`);
