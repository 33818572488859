import { NonFieldError } from 'src/app/models/authentication';
import { DestroyRef, Directive, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { State } from 'src/app/reducers';
import { clearFormAndFieldErrors } from 'src/app/reducers/shared/shared.actions';
import { selectAuthenticationErrors } from 'src/app/reducers/shared/shared.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const NON_FIELD_ERRORS = 'non_field_errors';

@Directive({
  selector: 'form[formGroup][chefServerFormError]',
  standalone: true,
})
export class ServerFormErrorDirective implements OnInit {
  @Input() formId!: string;

  private serverErrorShown = false;

  constructor(
    private form: ControlContainer,
    private store: Store<State>,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.form?.valueChanges &&
      this.form.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          if (this.serverErrorShown) {
            this.serverErrorShown = false;
            this.store.dispatch(
              clearFormAndFieldErrors({
                payload: NON_FIELD_ERRORS,
              }),
            );
          }
        });
    this.store
      .pipe(
        select(selectAuthenticationErrors),
        map(
          (error) =>
            error?.[NON_FIELD_ERRORS] &&
            (error[NON_FIELD_ERRORS] as NonFieldError[]).find(
              (err) => err.form_id === this.formId || err.form_id === undefined,
            ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((error) => {
        if (!error) return;
        this.serverErrorShown = true;
        this.form?.control &&
          this.form.control.setErrors(
            { error: error?.message },
            { emitEvent: true },
          );
      });
  }
}
