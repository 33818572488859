@if (numberOfPages > 1) {
  <div class="pagination">
    @for (dot of [].constructor(numberOfPages); track dot; let i = $index) {
      <button
        class="page-dot"
        [ngClass]="currentPage === i + 1 ? 'active' : ''"
        (click)="pageClick.emit(i)"
      ></button>
    }
  </div>
}
