import { createAction, props } from '@ngrx/store';
import { Results } from 'src/app/models/generics';
import {
  ReservationDetail,
  SectionTicket,
  ServiceStatus,
} from 'src/app/models/tickets';
import { WebsocketAction } from 'src/app/models/webservice';

export const fetchSectionTickets = createAction(
  `[Tickets] Fetch section tickets`,
  props<{
    section: string;
    service_status: ServiceStatus;
    page_size: number;
    item_baselang?: string | null;
    table_number?: number | null;
    name_room?: string | number | null;
    page?: number;
  }>(),
);

export const fetchAllSectionTickets = createAction(
  `[Tickets] Fetch all section tickets`,
  props<{
    section?: string;
    item_baselang?: string | null;
    table_number?: number | null;
    name_room?: string | number | null;
    service_status?: ServiceStatus;
  }>(),
);

export const fetchSectionTicketsByUrl = createAction(
  `[Tickets] Fetch section tickets by URL`,
  props<{
    url: string;
    section: string;
    page: number;
    service_status: ServiceStatus;
    showLoading: boolean;
  }>(),
);

export const updateTicketStatus = createAction(
  `[Tickets] Update ticket status`,
  props<{ ids: number[]; data: { [key: string]: number }; section: string }>(),
);

export const setUpdatedTicketStatus = createAction(
  `[Tickets] Set updated ticket status locally`,
  props<{ ids: number[]; service_status: ServiceStatus; section: string }>(),
);

export const setUpdatedTickets = createAction(
  `[Tickets] Set updated tickets locally`,
  props<{
    ids: number[];
    service_status: ServiceStatus;
    section: string;
    items: SectionTicket[];
  }>(),
);

export const undoTicketStatus = createAction(
  `[Tickets] Undo ticket status`,
  props<{ ids: number[]; data: { [key: string]: number }; section: string }>(),
);

export const setUndoTicketStatus = createAction(
  `[Tickets] Set undo ticket status locally`,
  props<{
    ids: number[];
    service_status: ServiceStatus;
    section: string;
    new_service_status?: ServiceStatus;
  }>(),
);

export const deleteTicket = createAction(
  `[Tickets] Delete ticket`,
  props<{
    ids: number[];
    data: { service_status: ServiceStatus };
    section: string;
    count: number;
  }>(),
);

export const deleteTicketLocally = createAction(
  `[Tickets] Delete ticket locally`,
  props<{
    ids: number[];
    service_status: ServiceStatus;
    section: string;
    pageSize: number;
    count: number;
  }>(),
);

export const clearAllSectionTickets = createAction(
  `[Tickets] Clears all section tickets locally`,
);

export const clearSectionTickets = createAction(
  `[Tickets] Clears section tickets of a specific section locally`,
  props<{ section: string }>(),
);

export const setSectionTickets = createAction(
  `[Tickets] Set section tickets locally`,
  props<{
    service_status: ServiceStatus;
    payload: { [key: string]: Partial<Results<SectionTicket>> };
  }>(),
);

export const processWebsocketsData = createAction(
  `[Tickets] Processes Websockets data`,
  props<{
    message: {
      action: WebsocketAction;
      date: string;
      service: string;
      data: any;
    };
  }>(),
);

export const addSectionTicket = createAction(
  `[Tickets] Add section ticket locally`,
  props<{ payload: SectionTicket; page_size: number }>(),
);

export const setSectionTicketsLoading = createAction(
  `[Tickets] Set section tickets loading status locally`,
  props<{ service_status: ServiceStatus; section: string; loading: boolean }>(),
);

export const changeOrderStatus = createAction(
  `[Tickets] Change order status`,
  props<{
    ids: number[];
    previous_status: ServiceStatus;
    new_status: ServiceStatus;
    items: SectionTicket[];
    page_size: number;
  }>(),
);

export const changeReservationStatus = createAction(
  `[Reservations] Change reservation status`,
  props<{
    ids: number[];
    data: Partial<ReservationDetail>;
  }>(),
);

export const resetReservations = createAction(
  `[Reservations] Reset reservations`,
  props<{ ids: number[]; data: Partial<ReservationDetail> }>(),
);
