import { Action, createReducer, on } from '@ngrx/store';
import { KdsSettings, User } from 'src/app/models/user';

import {
  clearUser,
  setKdsSettings,
  setLogin,
  setLoginSpinner,
  setUser,
} from './user.actions';

export interface UserState {
  currentUser: User | null;
  isLogged: boolean | null;
  loading: boolean;
  kds_settings: KdsSettings | null;
}

export const initialState: UserState = {
  currentUser: null,
  isLogged: null,
  loading: false,
  kds_settings: null,
};

const _currentUserReducer = createReducer(
  initialState,
  on(setLogin, (state, { isLogged }) => ({ ...state, isLogged })),
  on(setUser, (state, { payload }) => ({ ...state, currentUser: payload })),
  on(setLoginSpinner, (state, { loading }) => ({ ...state, loading })),
  on(setKdsSettings, (state, { kdsSettings }) => ({
    ...state,
    kds_settings: {
      ...((state.kds_settings as KdsSettings) ?? {}),
      ...kdsSettings,
    },
  })),
  on(clearUser, () => initialState),
);

export function userReducer(state: UserState | undefined, action: Action) {
  return _currentUserReducer(state, action);
}
